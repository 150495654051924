import { useState, useEffect } from "react";
import "./Filter.css";
import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// Context
import { useContext } from "react";
import { Context9 } from "../../../../../context/Context";
import { Context10 } from "../../../../../context/Context";

const categoria = [
  { name: "todo", filtro: null },
  {
    name: "inflables",
    filtro: ["tamaño", "edad", "tipo"],
    tamaño: ["chico", "mediano", "grande"],
    edad: ["2-5 años", "5-9 años", "9-13 años"],
    tipo: [
      "con pelotero",
      "tematico",
      "blanco",
      "deporte",
      "exclusivo",
      "plotter",
    ],
  },
  {
    name: "acuaticos",
    filtro: ["tamaño", "edad", "tipo"],
    tamaño: ["chico", "mediano", "grande"],
    edad: ["2-7 años", "7-13 años"],
    tipo: ["con pileta", "al borde"],
  },
  { name: "carreras", filtro: null },
  { name: "kermesse", filtro: null },
  { name: "juegos", filtro: null },
  { name: "livings", filtro: ["tipo"], tipo: ["miniliving", "living"] },
  { name: "plaza blanda", filtro: null },
  { name: "combos", filtro: null },
  { name: "catering", filtro: null },
];

function Filtro(props) {
  // Ordenamiento
  const [order, setOrder] = useState("a-z"); // cambiar a "destacados"

  const handleChange = (e) => {
    setOrder(e.target.value);
  };

  const { ordenSeleccionado } = useContext(Context10);
  const [stateOrdenSeleccionado, setStateOrdenSeleccionado] = ordenSeleccionado;

  //
  useEffect(() => {
    setStateOrdenSeleccionado(order);
  }, [order]);

  // Categorias
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleCheckboxChange = (event, value, checkboxGroup) => {
    const isChecked = event.target.checked;

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      if (isChecked) {
        // Filtrar los checkboxes previamente seleccionados que pertenezcan al mismo grupo
        const updatedSelectedCheckboxes = prevSelectedCheckboxes.filter(
          (checkbox) => !checkboxGroup || !checkboxGroup.includes(checkbox)
        );

        return [...updatedSelectedCheckboxes, value];
      } else {
        return prevSelectedCheckboxes.filter((item) => item !== value);
      }
    });

    props.setFiltroSeleccionados((prevSelectedCheckboxes) => {
      if (isChecked) {
        // Filtrar los checkboxes previamente seleccionados que pertenezcan al mismo grupo
        const updatedSelectedCheckboxes = prevSelectedCheckboxes.filter(
          (checkbox) => !checkboxGroup || !checkboxGroup.includes(checkbox)
        );

        return [...updatedSelectedCheckboxes, value];
      } else {
        return prevSelectedCheckboxes.filter((item) => item !== value);
      }
    });
  };

  useEffect(() => {
    props.setStateFiltroSeleccionado(props.filtroSeleccionados);
  }, [selectedCheckboxes]);

  const handleClick = (opcion) => {
    const updatedFiltroSeleccionados = [opcion.name];

    setSelectedCheckboxes(props.subRadioOptions[opcion.name] || []);

    props.setFiltroSeleccionados(updatedFiltroSeleccionados);
    props.setSubRadioGroup(opcion.name);

    props.setStateFiltroSeleccionado(props.filtroSeleccionados);
  };

  const handleRadioClick = () => {
    const screenSize = window.innerWidth;
    if (screenSize < 960) {
      // Tamaños de pantalla xs y sm
      const tiendaElement = document.getElementById("tienda");
      window.scrollTo({
        top: tiendaElement.offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {/* <div>
        <div className="filtro-titulo">ordenar por</div>
        <FormControl sx={{ m: 1, width: "calc(100% - 15px)" }}>
          <Select
            value={order}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "55px", color: "white" }}
          >
            <MenuItem value={"destacados"}>Destacados</MenuItem>
            <MenuItem value={"a-z"}>Orden A-Z</MenuItem>
            <MenuItem value={"z-a"}>Orden Z-A</MenuItem>
          </Select>
        </FormControl>
      </div> */}

      <div>
        <div className="filtro-titulo">{props.tituloNombre}</div>
        <FormControl>
          {props.opciones.map((opcion) => (
            <div
              key={opcion.name}
              className={`filtro-opcion ${
                opcion.name === "inflables" ? "inflables" : ""
              }`}
            >
              <RadioGroup>
                <FormControlLabel
                  value={opcion.name}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "orange",
                        },
                      }}
                    />
                  }
                  label={opcion.name}
                  checked={props.filtroSeleccionados.includes(opcion.name)}
                  onClick={() => {
                    handleRadioClick();
                    handleClick(opcion);
                  }}
                />
                {opcion.filtro !== null &&
                props.subRadioGroup === opcion.name ? (
                  <div className="filtro-sub-opcion">
                    {opcion.filtro.map((subopcion) => (
                      <div key={subopcion}>
                        <div className="filtro-titulo">{subopcion}</div>
                        <FormGroup>
                          {opcion[subopcion].map((t) => (
                            <FormControlLabel
                              key={t}
                              control={
                                <Checkbox
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "orange",
                                    },
                                  }}
                                  checked={selectedCheckboxes.includes(t)}
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event,
                                      t,
                                      opcion[subopcion]
                                    )
                                  }
                                  onClick={handleRadioClick}
                                  value={t}
                                />
                              }
                              label={t}
                            />
                          ))}
                        </FormGroup>
                      </div>
                    ))}
                  </div>
                ) : null}
              </RadioGroup>
            </div>
          ))}
        </FormControl>
      </div>
    </>
  );
}

export default function Filter(props) {
  const [filtroSeleccionados, setFiltroSeleccionados] = useState(["todo"]);
  const [subRadioGroup, setSubRadioGroup] = useState(null);
  const [subRadioOptions, setSubRadioOptions] = useState({});

  const { filtroSeleccionado } = useContext(Context9);
  const [stateFiltroSeleccionado, setStateFiltroSeleccionado] =
    filtroSeleccionado;

  return (
    <>
      <div className="fondo-filtros">
        <Filtro
          tituloNombre="categoria"
          opciones={categoria}
          setSubRadioGroup={setSubRadioGroup}
          setFiltroSeleccionados={setFiltroSeleccionados}
          filtroSeleccionados={filtroSeleccionados}
          subRadioGroup={subRadioGroup}
          subRadioOptions={subRadioOptions}
          setStateFiltroSeleccionado={setStateFiltroSeleccionado}
        />
      </div>
    </>
  );
}
