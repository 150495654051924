import React from "react";
// Styles
import "./ProductsSelected.css";
// Material UI
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
// Components
import ProductSelectedName from "./components/ProductSelectedName";
import ProductSelectedPrice from "./components/ProductSelectedPrice";
import ProductSelectedImage from "./components/ProductSelectedImage";
import ProductSelectedEliminarBtn from "./components/ProductSelectedEliminar_btn";

export default function ProductsSelected({
  product: { id, name, price, image },
}) {
  return (
    <Box backgroundColor="white" sx={{ flexGrow: 1 }}>
      <div className="contenedorComponents">
        <div>
          {/* Nombre */}
          <ProductSelectedName name={name} />
        </div>
        <Box flexGrow="1"></Box>
        <div>
          {/* Boton Eliminar */}
          <ProductSelectedEliminarBtn id={id} />
        </div>
      </div>

      <div className="contenedorComponents">
        <div className="contenedorImage">
          {/* Imagen */}
          <ProductSelectedImage image={image} name={name} />
        </div>
        <Box flexGrow="1"></Box>
        <div>
          {/* Precio */}
          <ProductSelectedPrice price={price} />
        </div>
      </div>
      <Divider />
    </Box>
  );
}
