import React from "react";
// Material UI
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// Hooks
import { useStateValue } from "../../../../../../../hooks/StateProvider";
import { actionTypes } from "../../../../../../../reducer";

function ProductSelectedEliminarBtn(props) {
  const [{ basket }, dispatch] = useStateValue();
  const removeItem = () =>
    dispatch({
      type: actionTypes.REMOVE_ITEM,
      id: props.id,
    });
  return (
    <IconButton onClick={removeItem}>
      <DeleteIcon fontSize="small" padding="0" />
    </IconButton>
  );
}

export default ProductSelectedEliminarBtn;
