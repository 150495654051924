import React from "react";
// Material UI
import esLocale from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Firestore
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../../config";
// Hooks
import { useState, useEffect } from "react";
import { useStateValue } from "../../../../../hooks/StateProvider";
import { actionTypes } from "../../../../../reducer";
import { useHistoryEffects } from "../../../../../hooks/useHistoryEffects.js";
// Context
import { useContext } from "react";
import { Context, Context5, Context6 } from "../../../../../context/Context";

function Calendar() {
  const today = new Date();
  // Declaro variables para basket, para alerta modal al querer cambiar la fecha
  const [dateSelected, setDateSelected] = useState(null);
  const [dateSelectedTemp, setDateSelectedTemp] = useState(new Date());
  // Declaro variable para guardar los dias deshabilitados
  const [disableDates, setDisableDates] = useState([]);
  // Cerrar y abrir calendario
  const [openCalendar, setOpenCalendar] = useState(false);
  // Guardar fecha en context
  const { date, rawDate } = useContext(Context);
  const [stateDate, setStateDate] = date;
  const [stateRawDate, setStateRawDate] = rawDate;
  const [dateSelectedTemp2, setDateSelectedTemp2] = useState(null);
  //Guardar mes en context
  const { dateMonth } = useContext(Context5);
  const [stateDateMonth, setStateDateMonth] = dateMonth;
  // PAra resetear parte de tienda
  const { resetTienda } = useContext(Context6);
  const [stateResetTienda, setStateResetTienda] = resetTienda;

  // Nombre meses
  const monthNames = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];
  useEffect(() => {
    if (dateSelected != null) {
      const day = dateSelected.getDate().toString().padStart(2, "0");
      const month = (dateSelected.getMonth() + 1).toString().padStart(2, "0");
      const year = dateSelected.getFullYear().toString();

      const formattedDate = `${day}-${month}-${year}`;
      setStateDate(formattedDate);

      setStateDateMonth(monthNames[dateSelected.getMonth()]);
      setStateRawDate(dateSelected);
    }
  }, [dateSelected]);

  useEffect(() => {
    setDateSelectedTemp2(stateRawDate);
  }, [stateRawDate]);

  // Se recorre base de datos
  useEffect(() => {
    if (stateResetTienda) {
      setDateSelectedTemp2("");
    }

    const obtenerDocumentos = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "RESERVAS"));
        let disableDatesTemp = [];
        let promises = [];

        querySnapshot.forEach((documento) => {
          const fecha = documento.id;
          const habilitado = documento.data().habilitado;

          const promise = habilitado === false ? fecha : null;

          if (promise !== null) {
            promises.push(promise);
          }
        });

        if (promises.length === 0) {
          setDisableDates(false); // Establecer disableDates en false
        } else {
          const results = await Promise.all(promises);
          disableDatesTemp = results.filter(Boolean);
          setDisableDates(disableDatesTemp);
        }
      } catch (error) {
        console.log("Error obteniendo documentos:", error);
      }
    };

    obtenerDocumentos();
  }, []);

  // Se deshabilita los días en los cuales ya hay 6 reservas
  const DisableDate = (date) => {
    if (disableDates !== false) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      const dateFormat = `${day}-${month}-${year}`;
      return disableDates.includes(dateFormat) || date <= today;
    }
    console.log(date, today);
    return false;
  };

  // Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClose = () => setOpenDialog(false);

  const deleteCarritoItems = (newValue) => {
    // Si hay productos en el carrito se muestra alerta
    if (basket.length > 0) {
      // Abre modal
      setOpenDialog(true);
      // Guardar dia elegido
      setDateSelectedTemp(newValue);
    }
    // Si no tiene productos en el carrito, no se muestra alerta
    else {
      setDateSelectedTemp2(newValue);
    }
  };

  // Fecha seleccionada mostrar en input
  const fechaSeleccionada = () => {
    setDateSelected(dateSelectedTemp2);
  };

  // Llamo constante de array con productos elegidos
  const [{ basket }, dispatch] = useStateValue();
  const dateChange = () => {
    // Cambio fecha
    setDateSelected(dateSelectedTemp);
    setDateSelectedTemp2(dateSelectedTemp);
    setStateRawDate(dateSelectedTemp);
    // Quitar todos los productos elegidos del carrito, y mostrar alerta
    basket.map((product) => {
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        id: product.id,
      });
    });
    setOpenDialog(false);
  };

  // Obtener el último día del mes actual
  const ultimoDiaMesActual = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  ).getDate();

  // Verificar si hoy es el último o el anteúltimo día del mes
  const mostrarMesSiguiente = today.getDate() === ultimoDiaMesActual;

  // Calcular el mes para mostrar por defecto en el calendario
  const defaultCalendarMonth = mostrarMesSiguiente
    ? new Date(today.getFullYear(), today.getMonth() + 1)
    : today;

  // Función para abrir el MobileDatePicker
  const handleOpenDatePicker = () => {
    setOpenCalendar(true);
  };

  // Función para cerrar el MobileDatePicker
  const handleCloseDatePicker = () => {
    setOpenCalendar(false);
    setOpenDialog(false);
  };

  // Cerrar productsView con flecha atras
  useHistoryEffects(openCalendar, setOpenCalendar);
  // Cerrar productsView con flecha atras
  useHistoryEffects(openDialog, setOpenDialog);
  console.log(stateDate);
  return (
    <>
      {disableDates.length > 0 || disableDates === false ? (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <MobileDatePicker
            className={stateDate === null ? "inputRed" : null}
            value={dateSelectedTemp2}
            open={openCalendar}
            onOpen={handleOpenDatePicker}
            onClose={handleCloseDatePicker}
            styles={{ border: "1px solid red" }}
            label="Seleccione una fecha"
            onChange={deleteCarritoItems}
            onAccept={fechaSeleccionada}
            shouldDisableDate={DisableDate}
            disableHighlightToday
            disablePast
            maxDate={
              new Date(
                today.getFullYear(),
                today.getMonth() + 3,
                today.getDate()
              )
            }
            defaultCalendarMonth={defaultCalendarMonth}
            sx={{ width: "100%" }}
          />
        </LocalizationProvider>
      ) : (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <TextField
            label="Seleccione una fecha"
            disabled
            sx={{ width: "100%" }}
          />
        </LocalizationProvider>
      )}
      {/* Dialog alert */}
      <Dialog
        disableScrollLock={true}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {"Cambiar fecha de reserva"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Si seleccionas otra fecha, los productos que hayas agregado al
              carrito para la fecha actual serán eliminados. ¿Deseas continuar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button onClick={dateChange} autoFocus color="success">
              Aceptar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Calendar;
