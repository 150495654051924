import React from "react";
// Material UI
import Grid from "@mui/material/Grid";
// Media
import logoNegro from "../../../assets/images/logos/logo-negro.png";
import globosFooter from "../../../assets/images/otros/globos-footer.png";
// Estilos
import "./Footer.css";

function Footer() {
  return (
    <>
      <Grid className="footer" container spacing={0} alignItems="center">
        <Grid item lg={0}></Grid>
        <Grid item lg={9}>
          <img src={logoNegro} alt="" width="350px" />
        </Grid>
        <Grid
          item
          lg={3}
          display={{ lg: "block", md: "none", sm: "none", xs: "none" }}
        >
          <img className="globos-footer" src={globosFooter} alt="" />
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
