import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@mui/material/Grid";
import logo from "../../../../../assets/images/logos/logo.png";
import medalla from "../../../../../assets/images/header/medalla-15-años.png";

import "./Slider.css";

export default function SliderHeader({ setThreshold }) {
  // Obtener referencia de la parte inferior del slider, para que el navbar se ponga en fixed cuando se haga scroll hasta esa posicion
  const sliderRef = useRef(null);

  const images_horizontal = [
    require("../../../../../assets/images/header/horizontal/1.jpg"),
    require("../../../../../assets/images/header/horizontal/2.jpg"),
    require("../../../../../assets/images/header/horizontal/3.jpg"),
    require("../../../../../assets/images/header/horizontal/4.jpg"),
    require("../../../../../assets/images/header/horizontal/5.jpg"),
    require("../../../../../assets/images/header/horizontal/6.jpg"),
    require("../../../../../assets/images/header/horizontal/7.jpg"),
    require("../../../../../assets/images/header/horizontal/8.jpg"),
    require("../../../../../assets/images/header/horizontal/9.jpg"),
    require("../../../../../assets/images/header/horizontal/10.jpg"),
  ];
  const images_vertical = [
    require("../../../../../assets/images/header/vertical/1.jpg"),
    require("../../../../../assets/images/header/vertical/2.jpg"),
    require("../../../../../assets/images/header/vertical/3.jpg"),
    require("../../../../../assets/images/header/vertical/4.jpg"),
    require("../../../../../assets/images/header/vertical/5.jpg"),
    require("../../../../../assets/images/header/vertical/6.jpg"),
    require("../../../../../assets/images/header/vertical/7.jpg"),
    require("../../../../../assets/images/header/vertical/8.jpg"),
  ];

  const settings = {
    autoplay: true,
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    pauseOnHover: false,
  };

  // Se calcula el threshold
  useEffect(() => {
    const calculateThreshold = () => {
      const sliderElement = sliderRef.current;
      const sliderRect = sliderElement.getBoundingClientRect();

      const sliderTop = sliderRect.top + window.pageYOffset;
      const sliderHeight = sliderRect.height;

      let threshold = sliderTop + sliderHeight - 2;

      if (window.innerWidth < 960) {
        // Tamaño "sm" y "xs"
        threshold -= 82;
      }

      setThreshold(threshold);
    };

    calculateThreshold();

    window.addEventListener("resize", calculateThreshold);

    return () => {
      window.removeEventListener("resize", calculateThreshold);
    };
  }, [setThreshold]);

  return (
    <>
      {/* SLIDER NORMAL */}
      <Grid
        item
        display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
        sx={{ position: "relative" }}
      >
        <img className="logo" src={logo} alt="Logo" />
        <img className="medalla" src={medalla} alt="15 Años" />

        <Slider className="slider" {...settings}>
          {images_horizontal.map((image, index) => (
            <img
              className="sliderImages"
              key={index}
              src={image}
              alt={""}
              loading="lazy"
            />
          ))}
        </Slider>
      </Grid>

      {/* SLIDER RESPONSIVE HORIZONTAL sm */}
      <Grid
        item
        display={{ lg: "none", md: "none", sm: "block", xs: "none" }}
        sx={{ position: "relative" }}
        className="slider-container-responsive"
      >
        {/* <img className="logo-responsive" src={logo} alt="Logo" /> */}

        <Slider className="slider-reponsive" {...settings}>
          {images_horizontal.map((image, index) => (
            <img
              className="sliderImages-responsive"
              key={index}
              src={image}
              alt={""}
              loading="lazy"
            />
          ))}
        </Slider>
      </Grid>
      {/* SLIDER RESPONSIVE VERTICAL sx */}
      <Grid
        item
        display={{ lg: "none", md: "none", sm: "none", xs: "block" }}
        sx={{ position: "relative" }}
        className="slider-container-responsive"
      >
        {/* <img className="logo-responsive" src={logo} alt="Logo" /> */}

        <Slider className="slider-reponsive" {...settings}>
          {images_vertical.map((image, index) => (
            <img
              className="sliderImages-responsive"
              key={index}
              src={image}
              alt={""}
              loading="lazy"
            />
          ))}
        </Slider>
      </Grid>
      <div ref={sliderRef}></div>
    </>
  );
}
