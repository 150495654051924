import * as React from "react";
// Material UI
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// Firestore
import {
  getDoc,
  doc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../config";
// Hooks
import { useState, useEffect } from "react";

const columns = [
  { field: "fecha", headerName: "Fecha", width: 130 },
  { field: "nroPedido", headerName: "Nro pedido", width: 100 },
  { field: "especificaciones", headerName: "Especificaciones", width: 500 },
  { field: "precio", headerName: "Precio", width: 100 },
  { field: "nombre", headerName: "Nombre", width: 100 },
  { field: "apellido", headerName: "Apellido", width: 100 },
  { field: "telefono", headerName: "Telefono", width: 150 },
  { field: "localidad", headerName: "Localidad", width: 150 },
  { field: "direccion", headerName: "Direccion", width: 200 },
  { field: "mail", headerName: "Mail", width: 200 },
  { field: "horario", headerName: "Horario", width: 100 },
];

export default function PedidosPendientes() {
  // Declaro variable para guardar los pedidos pendientes
  const [pedidosPendientes, setPedidosPendientes] = useState([]);

  // Se declara una dependencia para el useEffect para recargar la tabla al rechazar o aceptar un pedido
  const [updateTabla, setUpdateTabla] = useState(false);

  // Variables de loading
  const [isLoading, setIsLoading] = useState(false);

  // Display de los botones, que aparezcan cuando se selecciona una fila
  const [buttonsDisplay, setButtonsDisplay] = useState("hidden");

  // Se declara variable para guardar los id de los productos que se seleccionen con el check
  const [productosChecked, setProductosChecked] = useState([]);

  // Se declara variable para guardar el idpago y url para cuando se acepta pedido en firebase
  const [idpago, setIdpago] = useState("");
  const [url, setUrl] = useState("");
  const [urlCambiada, setUrlCambiada] = useState(false);
  const [idpagoCambiada, setIdpagoCambiada] = useState(false);

  // Dialog
  const [open, setOpen] = React.useState(false);
  const [textoDialog, setTextoDialog] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };

  // Variable dia en 48hs
  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + 2);
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const pasadoMañana = formatDate(futureDate).toString();

  // Se recorre base de datos
  useEffect(() => {
    setIsLoading(true);
    const obtenerDocumentos = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "RESERVAS"));
        let pedidosPendientesTemp = [];
        let promises = [];
        let idCount = 0; // Variable para almacenar el contador de ID

        querySnapshot.forEach((documento) => {
          const fecha = documento.id;
          const nroPedidos = documento.data().nroPedidos;

          if (Array.isArray(nroPedidos) && nroPedidos.length > 0) {
            nroPedidos.forEach((nroPedido) => {
              const docRef = doc(
                db,
                "RESERVAS",
                fecha,
                nroPedido.toString(),
                "Datos"
              );
              const promise = getDoc(docRef).then((docSnap2) => {
                if (
                  docSnap2.exists() &&
                  docSnap2.data().B6aprobado === false &&
                  docSnap2.data().B7rechazado === false
                ) {
                  const pedido = {
                    id: idCount, // Utiliza el contador como ID numérico secuencial
                    dni: docSnap2.data().A1DNI,
                    nombre: docSnap2.data().A2nombre,
                    apellido: docSnap2.data().A3apellido,
                    fecha: fecha,
                    especificaciones: docSnap2.data().A5especificaciones,
                    nroPedido: docSnap2.data().A6nroPedido,
                    localidad: docSnap2.data().A7localidad,
                    direccion: docSnap2.data().A8direccion,
                    lote: docSnap2.data().A9lote,
                    horario: docSnap2.data().B1horario,
                    precio: docSnap2.data().B2precio,
                    seña: docSnap2.data().B3seña,
                    telefono: docSnap2.data().B4telefono,
                    mail: docSnap2.data().B5mail,
                  };
                  idCount++; // Incrementa el contador de ID
                  return pedido;
                }
              });
              promises.push(promise);
            });
          }
        });

        const results = await Promise.all(promises);
        pedidosPendientesTemp = results.filter(Boolean);

        setPedidosPendientes(pedidosPendientesTemp);
        setIsLoading(false);
      } catch (error) {
        console.log("Error obteniendo documentos:", error);
        setIsLoading(false);
      }
    };

    obtenerDocumentos();
  }, [updateTabla]);

  const selectedChange = (e) => {
    setProductosChecked(e);
    if (e.length > 0) {
      setButtonsDisplay("visible");
    } else {
      setButtonsDisplay("hidden");
    }
  };

  // Cancelar pedido/s indicado/s
  const RechazarPedido = async () => {
    for (let i = 0; i < productosChecked.length; i++) {
      const docRef = doc(
        db,
        "RESERVAS",
        pedidosPendientes[productosChecked[i]].fecha
      );
      const docSnap = await getDoc(docRef);

      let currentIds = [];
      if (docSnap.exists() && docSnap.data().hasOwnProperty("ids")) {
        currentIds = Array.isArray(docSnap.data().ids)
          ? docSnap.data().ids
          : [docSnap.data().ids];
      }
      let currentItems = [];
      if (docSnap.exists() && docSnap.data().hasOwnProperty("items")) {
        currentItems = Array.isArray(docSnap.data().items)
          ? docSnap.data().items
          : [docSnap.data().items];
      }

      const docRef2 = doc(
        db,
        "RESERVAS",
        pedidosPendientes[productosChecked[i]].fecha,
        pedidosPendientes[productosChecked[i]].nroPedido.toString(),
        "Datos"
      );
      const docSnap2 = await getDoc(docRef2);

      const newIds = currentIds.filter(
        (id) => !docSnap2.data().C3ids.includes(id)
      ); // Eliminar los ids de los inflables del pedido indicado
      const newItems = currentItems.filter(
        (item) => !docSnap2.data().C4items.includes(item)
      ); // Eliminar los items de los inflables del pedido indicado

      updateDoc(
        doc(
          db,
          "RESERVAS",
          pedidosPendientes[productosChecked[i]].fecha,
          pedidosPendientes[productosChecked[i]].nroPedido.toString(),
          "Datos"
        ),
        {
          B7rechazado: true,
          B8manual: true,
          B9cargado: false,
        }
      );

      // Actualizar valores de ids y items de coleccion fecha, sin incluir los seleccionados
      updateDoc(
        doc(db, "RESERVAS", pedidosPendientes[productosChecked[i]].fecha),
        {
          ids: newIds,
          items: newItems,
        }
      );
    }
    setOpen(false);
    setButtonsDisplay("hidden");
    setUpdateTabla(!updateTabla);
    // Vaciar el array de productos seleccionados
    setProductosChecked([]);

    // Desmarcar todas las filas seleccionadas
    const updatedRows = pedidosPendientes.map((row) => ({
      ...row,
      _selected: false,
    }));
    setPedidosPendientes(updatedRows);
  };

  // Aceptar pedido
  const AceptarPedido = async () => {
    for (let i = 0; i < productosChecked.length; i++) {
      const nombre = pedidosPendientes[productosChecked[i]].nombre;
      const apellido = pedidosPendientes[productosChecked[i]].apellido;
      const seña = pedidosPendientes[productosChecked[i]].seña;

      try {
        const pago = await CrearUrl(nombre, apellido, seña); // Wait for CrearUrl to complete
        updateDoc(
          doc(
            db,
            "RESERVAS",
            pedidosPendientes[productosChecked[i]].fecha,
            pedidosPendientes[productosChecked[i]].nroPedido.toString(),
            "Datos"
          ),
          {
            B6aprobado: true,
            B8manual: true,
            B9cargado: false,
            C1idpago: pago[0],
            C2url: pago[1],
          }
        );
      } catch (error) {
        // Handle any errors that occurred during the process
        console.error("Error:", error);
      }
    }

    setOpen(false);
    setButtonsDisplay("hidden");
    setUpdateTabla(!updateTabla);
    // Vaciar el array de productos seleccionados
    setProductosChecked([]);

    // Desmarcar todas las filas seleccionadas
    const updatedRows = pedidosPendientes.map((row) => ({
      ...row,
      _selected: false,
    }));
    setPedidosPendientes(updatedRows);
  };

  const CrearUrl = async (nombre, apellido, seña) => {
    const cuerpo =
      '{"payment_request":{"description":"Reserva evento","first_due_date":"' +
      pasadoMañana +
      '","first_total":"' +
      seña +
      '","payer_name":"' +
      nombre +
      " " +
      apellido +
      '","back_url_success":"http://localhost:3000/payment/finalizado","back_url_pending":"http://localhost:3000/payment/finalizado","excluded_installments":[3,6,9,12]}}';
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer YmE0YmUwNmVmN2UwNzI0MmIyM2E1Njc2NzZhYmZjNGMwZTBkYTc0MmI0YjA4N2YyNGI3MzRlNDgzZTU0NGUwZA",
      },
      body: cuerpo,
    };

    const response = await fetch(
      "https://api.pagos360.com/payment-request",
      requestOptions
    );
    const data = await response.json();
    return [data.id, data.checkout_url];
  };

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          marginBottom={2}
        >
          <Grid item visibility={buttonsDisplay}>
            <Button
              className="aceptar-pedido-btn"
              variant="contained"
              aria-label="Aceptar"
              onClick={() => {
                setTextoDialog("aceptar");
                setOpen(true);
              }}
            >
              <CheckIcon />
              Aceptar Pedido
            </Button>
          </Grid>
          <Grid item visibility={buttonsDisplay}>
            <Button
              className="rechazar-pedido-btn"
              variant="contained"
              aria-label="Aceptar"
              onClick={() => {
                setTextoDialog("rechazar");
                setOpen(true);
              }}
            >
              <ClearIcon />
              Rechazar Pedido
            </Button>
          </Grid>
        </Grid>
        <DataGrid
          rows={pedidosPendientes}
          columns={columns}
          autoWidth
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          checkboxSelection
          selectionModel={productosChecked}
          onSelectionModelChange={selectedChange}
          loading={isLoading}
          loadingOverlay={
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
        />
      </div>
      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {(textoDialog === "rechazar" ? "Eliminación " : "Confirmación ") +
              "de pedido pendiente"}
          </DialogTitle>
          <DialogContent>
            Está seguro que quiere{" "}
            <span
              className={
                textoDialog === "rechazar"
                  ? "texto-resaltado-dialog-rojo"
                  : "texto-resaltado-dialog-verde"
              }
            >
              {textoDialog}
            </span>{" "}
            {productosChecked.length === 1
              ? "este pedido pendiente "
              : "estos pedidos pendientes"}
            ?
            <div className="dialog-todos-los-datos-container">
              <hr className="violet-bar" />
              {productosChecked.map((id) => (
                <>
                  <div className="dialog-datos-container">
                    <p>
                      <span className="texto-resaltado-dialog">Fecha:</span>{" "}
                      {pedidosPendientes[id].fecha}
                    </p>
                    <p>
                      <span className="texto-resaltado-dialog">
                        Especificaciones:{" "}
                      </span>
                      {pedidosPendientes[id].especificaciones}
                    </p>
                    <p>
                      <span className="texto-resaltado-dialog">Precio: </span>
                      {pedidosPendientes[id].precio}
                    </p>
                    <p>
                      <span className="texto-resaltado-dialog">
                        Localidad:{" "}
                      </span>
                      {pedidosPendientes[id].localidad}
                    </p>
                    <p>
                      <span className="texto-resaltado-dialog">Horario: </span>
                      {pedidosPendientes[id].horario}
                    </p>
                  </div>
                  <hr className="violet-bar" />
                </>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="button-dialog"
              color="error"
            >
              Cancelar
            </Button>
            {textoDialog === "rechazar" ? (
              <Button
                onClick={RechazarPedido}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            ) : (
              <Button
                onClick={AceptarPedido}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
