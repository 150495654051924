// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBaZiqueHVvi2YXVSp4qVujKKs2yb-NAV8",
  authDomain: "homekidsweb-30511.firebaseapp.com",
  projectId: "homekidsweb-30511",
  storageBucket: "homekidsweb-30511.appspot.com",
  messagingSenderId: "542563756392",
  appId: "1:542563756392:web:d5fe581a70ef88c2e9cedd",
  measurementId: "G-GK0ZJG2YY9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
