import { useEffect } from "react";

export const useHistoryEffects = (variable, setVariable) => {
  useEffect(() => {
    if (variable) {
      // Agregar una entrada al historial de navegación
      window.history.pushState({ dialogView: true }, "");
    } else {
      // Eliminar la entrada del historial de navegación
      window.history.replaceState(null, "");
    }
  }, [variable]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.showNavbar === true) {
        setVariable(true);
      } else {
        setVariable(false);
        document.body.style.overflow = "auto";
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
};
