import IconButton from "@mui/material/IconButton";
import { ShoppingCart } from "@mui/icons-material";
import { Badge } from "@mui/material";
// Hooks
import { useStateValue } from "../../../../../../../hooks/StateProvider";
// Context
import { useContext } from "react";
import { Context2 } from "../../../../../../../context/Context";
// Modules
import { useLocation } from "react-router-dom";

export default function CarritoDeCompras() {
  // Obtener datos del context
  const { reservarSectionDisplay } = useContext(Context2);
  const [stateReservarSectionDisplay, setStateReservarSectionDisplay] =
    reservarSectionDisplay;
  // Abrir ReservarSection
  const abrirReservarSection = () => {
    setStateReservarSectionDisplay("block");
    document.body.style.overflow = "hidden";
  };

  const [{ basket }] = useStateValue();

  const { pathname } = useLocation();

  return (
    <IconButton
      className="carritoDeCompras"
      onClick={abrirReservarSection}
      aria-label="Mostrar los inflables del carrito"
      color="inherit"
      style={{
        display: pathname === "/payment" ? "none" : "block",
      }}
    >
      <Badge
        className="carritoBadge"
        badgeContent={basket.length}
        color="secondary"
      >
        <ShoppingCart className="carritoIcon" style={{ fontSize: "30px" }} />
      </Badge>
    </IconButton>
  );
}
