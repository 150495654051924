// Importar las dependencias necesarias
import React, { useContext } from "react";
import { Context } from "../context/Context";

export function IsDateWithinRange() {
  // Obtener la fecha almacenada en el context
  const { rawDate } = useContext(Context);
  const [stateRawDate, setStateRawDate] = rawDate;

  const currentDate = new Date();

  const currentMonth = stateRawDate !== null ? stateRawDate.getMonth() : currentDate.getMonth();
  return currentMonth >= 9 || currentMonth <= 2;
}
