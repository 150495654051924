import React from "react";
function ProductSelectedNamePrice(props) {
  return (
    <p color="blue">
      <br />
      <br />${props.price.toLocaleString().replace(",", ".")}
    </p>
  );
}

export default ProductSelectedNamePrice;
