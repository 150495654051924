import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Inicio from "../../../views/Inicio";
import Admin from "../../../views/Admin";
import FormPayment from "../../../views/FormPayment";
import PaymentFinalizado from "../../../views/PaymentFinalizado";
import {
  Fecha,
  ReservarSectionDisplay,
  CityZona,
  CitySelected,
  DateMonth,
  ResetTienda,
  PrecioMinimo,
  AccesoPayment,
  FiltroSeleccionado,
  OrdenSeleccionado,
  Context8,
} from "../../../context/Context";

function MainLayout() {
  return (
    <Fecha>
      <DateMonth>
        <ReservarSectionDisplay>
          <CityZona>
            <CitySelected>
              <ResetTienda>
                <PrecioMinimo>
                  <AccesoPayment>
                    <FiltroSeleccionado>
                      <OrdenSeleccionado>
                        <Context8.Consumer>
                          {({ accesoPayment }) => {
                            const [stateAccesoPayment, setStateAccesoPayment] =
                              accesoPayment;

                            return (
                              <Router>
                                {" "}
                                {/* Añade el componente Router aquí */}
                                <Routes>
                                  {/* Pagina principal */}
                                  <Route path="/" element={<Inicio />} />
                                  {/* FormPayment */}
                                  <Route
                                    path="/payment"
                                    element={
                                      stateAccesoPayment ? (
                                        <FormPayment />
                                      ) : (
                                        <Navigate to="/" replace />
                                      )
                                    }
                                  />
                                  {/* Payment finalizado */}
                                  <Route
                                    path="/payment/finalizado"
                                    element={<PaymentFinalizado />}
                                  />
                                  {/* Filtros */}
                                  <Route path=":filter" element={<Inicio />} />
                                  {/* Admin */}
                                  <Route path="/admin" element={<Admin />} />
                                  {/* Error 404 */}
                                  <Route path="*" element={<Inicio />} />
                                </Routes>
                              </Router>
                            );
                          }}
                        </Context8.Consumer>
                      </OrdenSeleccionado>
                    </FiltroSeleccionado>
                  </AccesoPayment>
                </PrecioMinimo>
              </ResetTienda>
            </CitySelected>
          </CityZona>
        </ReservarSectionDisplay>
      </DateMonth>
    </Fecha>
  );
}

export default MainLayout;
