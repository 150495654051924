import React from "react";
import { useState, useEffect } from "react";
// Material UI
import esLocale from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// Firestore
import {
  doc,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../config";

export default function DíasDeshabilitados() {
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");

  // Se declara una dependencia para el useEffect para recargar el calendario al deshabilitar o habilitar una fecha
  const [updateCalendar, setUpdateCalendar] = useState(false);

  // Se declara estado para guardar valor booleano segun si existe o no fecha seleccionada en firestore
  const [documentoExistente, setDocumentoExistente] = useState();
  // Se declara estado para guardar valor booleano segun si habilitado es true o false en la fecha seleccionada en firestore
  const [isAutomatico, setIsAutomatico] = useState();
  // Se declara estado para guardar valor cantidad de items que hay en la fecha seleccionada en firestore
  const [numeroItems, setNumeroItems] = useState();
  const [dateFormat, setDateFormat] = useState();
  // Declara estado del limite de inflables para el dia seleccionado
  const [limiteActual, setLimiteActual] = useState(0);
  const [limite, setLimite] = useState(0);
  // Declara estado para guardar display del boton establecer limite para que aparezca cuando se igresa un nuevo valor de limite en el input
  const [btnLimiteDisplay, setBtnLimiteDisplay] = useState(false);

  // Dialog
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  useEffect(() => {
    if (fechaSeleccionada !== "") {
      const day = fechaSeleccionada.getDate().toString().padStart(2, "0");
      const month = (fechaSeleccionada.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const year = fechaSeleccionada.getFullYear().toString();

      setDateFormat(`${day}-${month}-${year}`);
    }
  }, [fechaSeleccionada]);

  const verificarExistenciaDocumento = async () => {
    if (fechaSeleccionada !== "") {
      const docRef = doc(db, "RESERVAS", dateFormat);
      try {
        const docSnapshot = await getDoc(docRef);
        const existe = docSnapshot.exists();
        const estaAutomatico =
          existe && docSnapshot.data().automatico !== undefined
            ? docSnapshot.data().automatico
            : true;
        const cantItems =
          existe && docSnapshot.data() && docSnapshot.data().nroPedidos
            ? docSnapshot.data().nroPedidos.length
            : null;
        const numLimite =
          existe && docSnapshot.data() ? docSnapshot.data().limite : 8;

        setDocumentoExistente(existe);
        setIsAutomatico(estaAutomatico);
        setNumeroItems(cantItems);
        setLimite(numLimite);
        setLimiteActual(numLimite);
        setBtnLimiteDisplay(false);
      } catch (error) {
        console.log("Error al obtener el documento:", error);
      }
    }
  };

  const ManualizarAutomatizar = (booleano) => {
    const docRef = doc(db, "RESERVAS", dateFormat);
    if (documentoExistente) {
      // El documento existe
      updateDoc(docRef, {
        automatico: booleano,
      });
    } else {
      // El documento no existe
      setDoc(docRef, {
        automatico: booleano,
        limite: parseInt(8),
        habilitado: true,
        ids: [],
        items: [],
        nroPedidos: [],
      });
    }
    setUpdateCalendar(!updateCalendar);
    setIsAutomatico(!isAutomatico);
    setOpen(false);
  };

  const EstablecerLimite = () => {
    const docRef = doc(db, "RESERVAS", dateFormat);
    if (documentoExistente) {
      // El documento existe
      updateDoc(docRef, {
        limite: parseInt(limite),
      });
    } else {
      // El documento no existe
      setDoc(docRef, {
        limite: parseInt(limite),
        automatico: true,
        habilitado: true,
        ids: [],
        items: [],
        nroPedidos: [],
      });
    }
    setOpen2(false);
    verificarExistenciaDocumento();
  };

  // Declaro variable para guardar los dias deshabilitados
  const [manualDates, setManualDates] = useState([]);

  // Se recorre base de datos
  useEffect(() => {
    const obtenerDocumentos = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "RESERVAS"));
        let manualDatesTemp = [];
        let promises = [];

        querySnapshot.forEach((documento) => {
          const fecha = documento.id;
          const automatico = documento.data().automatico;

          const promise = automatico === false ? fecha : null;

          promises.push(promise);
        });

        const results = await Promise.all(promises);
        manualDatesTemp = results.filter(Boolean);

        setManualDates(manualDatesTemp);
      } catch (error) {
        console.log("Error obteniendo documentos:", error);
      }
    };

    obtenerDocumentos();
  }, [updateCalendar]);

  // Se deshabilita los días en los cuales ya hay 6 reservas
  const DisableDate = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;
    const day1 = props.day.getDate().toString().padStart(2, "0");
    const month2 = (props.day.getMonth() + 1).toString().padStart(2, "0");
    const year3 = props.day.getFullYear().toString();

    const dateFormat = `${day1}-${month2}-${year3}`;

    return (
      <Badge
        overlap="circular"
        badgeContent={manualDates.includes(dateFormat) ? "🔧" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={11}>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className="container-diasDeshabilitados"
            >
              <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={6} md={6} lg={6} marginBottom={2}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <MobileDatePicker
                      label="Seleccione una fecha"
                      value={fechaSeleccionada}
                      disablePast
                      disableHighlightToday
                      onChange={(nuevaFechaSeleccionada) => {
                        setFechaSeleccionada(nuevaFechaSeleccionada);
                      }}
                      onAccept={() => {
                        verificarExistenciaDocumento();
                      }}
                      slots={{
                        day: DisableDate,
                      }}
                    />
                  </LocalizationProvider>
                  <Grid item xs={12} sm={12} md={12} lg={12} marginTop={2}>
                    {fechaSeleccionada !== "" && isAutomatico !== undefined ? (
                      isAutomatico ? (
                        <Button
                          variant="contained"
                          className="rechazar-pedido-btn"
                          onClick={() => setOpen(true)}
                        >
                          Reserva manual
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          className="aceptar-pedido-btn"
                          onClick={() => setOpen(true)}
                        >
                          Reserva automatica
                        </Button>
                      )
                    ) : null}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  lg={5}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "white",
                      borderColor: "white",
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      "&.Mui-focused": {
                        color: "white",
                      },
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white",
                    },
                    "& input[disabled]": {
                      color: "rgba(255, 255, 255, 0.6)",
                    },
                  }}
                >
                  {isAutomatico ? (
                    <TextField
                      disabled={
                        fechaSeleccionada === "" && isAutomatico === undefined
                      }
                      value={limite}
                      onChange={(e) => {
                        setLimite(e.target.value);
                        // Permitir escribir solo numeros
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setLimite(numericValue);
                        // Mostrar boton de establecer limite cuando se escribe
                        setBtnLimiteDisplay(true);
                      }}
                      id="inputLimite"
                      InputProps={{
                        readOnly: false,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ color: "#fff" }}
                          >
                            Límite:{" "}
                          </InputAdornment>
                        ),
                        inputProps: {
                          pattern: "[0-9]*",
                        },
                      }}
                      required
                    />
                  ) : null}
                  <Grid item xs={12} sm={12} md={12} lg={12} marginTop={2}>
                    {isAutomatico && btnLimiteDisplay ? (
                      <Button
                        variant="contained"
                        onClick={() => setOpen2(true)}
                      >
                        Establecer limite
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="container-diasDeshabilitados"
            >
              <Grid container alignItems="center" style={{ height: "100%" }}>
                <Grid item lg={12}>
                  <p className="aclaracion-txt">
                    {fechaSeleccionada !== "" && isAutomatico !== undefined
                      ? documentoExistente && numeroItems !== null
                        ? `Hay ${numeroItems} ${
                            numeroItems > 1
                              ? "pedidos registrados"
                              : "pedido registrado"
                          } para la fecha seleccionada`
                        : "No se ha realizado ningún pedido para la fecha seleccionada."
                      : "Seleccione una fecha"}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* DIALOG MANUALIZAR AUTOMATIZAR */}
      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {(isAutomatico === true ? "Hacer manual " : "Hacer Automático ") +
              "el día seleccionado"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Está seguro que quiere{" "}
              <span
                className={
                  isAutomatico === true
                    ? "texto-resaltado-dialog-rojo"
                    : "texto-resaltado-dialog-verde"
                }
              >
                {isAutomatico === true ? "hacer manual" : "hacer automático"}
              </span>{" "}
              los pedidos del día {dateFormat}?<br />
              {dateFormat !== ""
                ? documentoExistente && numeroItems !== null
                  ? `Hay ${numeroItems} ${
                      numeroItems > 1 ? "pedidos" : "pedido"
                    } para esa fecha.`
                  : "No se ha registrado ningún pedido para esa fecha."
                : "Seleccione una fecha"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="button-dialog"
              color="error"
            >
              Cancelar
            </Button>
            {isAutomatico === true ? (
              <Button
                onClick={() => ManualizarAutomatizar(false)}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            ) : (
              <Button
                onClick={() => ManualizarAutomatizar(true)}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* DIALOG LIMITE */}
      <Dialog
        disableScrollLock={true}
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            Establecer nuevo límite
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Está seguro que quiere cambiar el actual limite "{limiteActual}"
              a <span className="texto-resaltado-dialog"> {limite}</span> para
              el día {dateFormat}?<br />
              {dateFormat !== ""
                ? documentoExistente && numeroItems !== null
                  ? `Hay ${numeroItems} ${
                      numeroItems > 1 ? "pedidos" : "pedido"
                    } para esa fecha.`
                  : "No se ha registrado ningún pedido para esa fecha."
                : "Seleccione una fecha"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="button-dialog"
              color="error"
            >
              Cancelar
            </Button>

            <Button
              onClick={() => EstablecerLimite()}
              className="button-dialog"
              color="success"
            >
              Confirmar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
