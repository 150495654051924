import React from "react";
// Material UI
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
// Material UI - View Modal
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// Components
import ProductsView from "../../ProductsView";
// Hooks
import { useHistoryEffects } from "../../../../../../hooks/useHistoryEffects.js";

function ProductVerBtn(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    document.body.style.overflow = "hidden";
  };
  const handleDialogClose = () => {
    document.body.style.overflow = "auto";
    setOpen(false);
  };

  // Cerrar productsView con flecha atras
  useHistoryEffects(open, setOpen);

  return (
    <>
      <IconButton
        className="ver-btn"
        aria-label="Añadir al Carrito"
        onClick={handleClickOpen}
      >
        <RemoveRedEyeIcon />
        <Typography
          variant="body2"
          color="#730075"
          component="p"
          fontSize="20px"
        >
          Ver
        </Typography>
      </IconButton>
      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
            margin: 0,
          },
        }}
      >
        <DialogActions>
          <Button
            className="cerrar-button"
            onClick={handleDialogClose}
            startIcon={
              <Box
                component="span"
                sx={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ClearIcon />
              </Box>
            }
          >
            Cerrar
          </Button>
        </DialogActions>
        <DialogContent
          sx={{
            padding: (theme) => (theme.breakpoints.down("md") ? 0 : undefined),
          }}
        >
          <ProductsView
            id={props.id}
            item={props.item}
            name={props.name}
            price={props.price}
            image={props.image}
            size={props.size}
            age={props.age}
            plotter={props.plotter}
            descripcion={props.descripcion}
            city={props.city}
            date={props.date}
            handleDialogClose={handleDialogClose}
            productDisponibilidad={props.productDisponibilidad}
            consultar={props.consultar}
            filtro={props.filtro}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProductVerBtn;
