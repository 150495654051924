import React, { useState } from "react";
import CardMedia from "@mui/material/CardMedia";
import CustomLoadingGif from "../../../../../../assets/images/otros/loading-blanco.gif";

function ProductImage(props) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="product-image-container">
      {isLoading && (
        <div className="loading-overlay">
          <img style={{ width: "250px" }} src={CustomLoadingGif} alt="" />
        </div>
      )}
      <CardMedia
        className={`CardMedia ${isLoading ? "image-loading" : "image-loaded"}`}
        component="img"
        image={props.image}
        onLoad={handleImageLoad}
      />
    </div>
  );
}

export default ProductImage;
