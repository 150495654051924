import React, { useState } from "react";
// Styles
import "./Header.css";
//Components
import Navbar from "./components/Navbar";
import Slider from "./components/Slider";

export default function Header() {
  // Valor de referencia parte inferior de slider, pasada por el componente Slider
  const [threshold, setThreshold] = useState(null);

  return (
    <div className="headerContainer">
      <Slider setThreshold={setThreshold} />
      <Navbar threshold={threshold} />
    </div>
  );
}
