import React from "react";
// Styles
import "./App.css";
import "./styles/colors.css";
// Components
import MainLayout from "./components/layout/MainLayout";

function App() {
  return (
    <>
      <MainLayout />
    </>
  );
}

export default App;
