import { useState, useEffect, useRef } from "react";
// Material UI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CustomLoadingGif from "../../../assets/images/otros/loading-blanco.gif";
// Data
import products from "../../../product-data";
import { sortProducts } from "../../../product-data";
// Components
import Calendar from "./components/Calendar";
import City from "./components/City";
import ProductCard from "./components/ProductCard";
import Filter from "./components/Filter";
import Pagination from "./components/Pagination";
// Estilos
import "./Tienda.css";
// Firestore
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../config";
// Context
import { useContext } from "react";
import {
  Context,
  Context3,
  Context4,
  Context5,
  Context6,
  Context9,
  Context10,
} from "../../../context/Context";
// Hooks
import { useStateValue } from "../../../hooks/StateProvider";

export default function Tienda() {
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage] = useState(9);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(products.length / elementsPerPage)
  );

  const startIndex = (currentPage - 1) * elementsPerPage;
  const endIndex = startIndex + elementsPerPage;

  const [currentElements, setCurrentElements] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // Ordenamiento
  const { ordenSeleccionado } = useContext(Context10);
  const [stateOrdenSeleccionado, setStateOrdenSeleccionado] = ordenSeleccionado;

  // Ordena los productos por el atributo 'name' de la A-Z
  const customCompareAlfabeticoAZ = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    // Separa el texto y los números en el nombre
    const partsA = nameA.split(/(\d+)/).filter(Boolean);
    const partsB = nameB.split(/(\d+)/).filter(Boolean);

    // Compara parte alfabética
    const alphaComparison = partsA[0].localeCompare(partsB[0]);

    if (alphaComparison !== 0) {
      return alphaComparison;
    }

    // Si las partes alfabéticas son iguales, compara las partes numéricas como números
    const numA = parseInt(partsA[1], 10) || 0;
    const numB = parseInt(partsB[1], 10) || 0;

    return numA - numB;
  };

  // Ordena los productos por el atributo 'name' de la Z-A
  const customCompareAlfabeticoZA = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    // Separa el texto y los números en el nombre
    const partsA = nameA.split(/(\d+)/).filter(Boolean);
    const partsB = nameB.split(/(\d+)/).filter(Boolean);

    // Compara parte alfabética
    const alphaComparison = partsA[0].localeCompare(partsB[0]) * -1; // Invertir el orden alfabético

    if (alphaComparison !== 0) {
      return alphaComparison;
    }

    // Si las partes alfabéticas son iguales, compara las partes numéricas como números
    const numA = parseInt(partsA[1], 10) || 0;
    const numB = parseInt(partsB[1], 10) || 0;

    return numA - numB;
  };

  // Ordena los productos por el atributo 'id' por destacados
  const customCompareId = (a, b) => {
    const indexA = sortProducts.indexOf(a.id);
    const indexB = sortProducts.indexOf(b.id);

    if (indexA === -1 || indexB === -1) {
      // Si alguno de los IDs no está en el array de orden, puedes decidir cómo manejarlos
      // Aquí, por ejemplo, los pongo al final
      return indexA === -1 ? 1 : -1;
    }

    return indexA - indexB;
  };

  const sortedProducts = products.slice().sort((a, b) => {
    switch (stateOrdenSeleccionado) {
      case "a-z":
        return customCompareAlfabeticoAZ(a, b);
      case "z-a":
        return customCompareAlfabeticoZA(a, b);
      case "destacados":
        return customCompareId(a, b);
      default:
        // Orden por defecto (destacados)
        return customCompareId(a, b);
    }
  });

  // Filtros sin resultado
  const [noResultsFiltros, setNoResultsFiltros] = useState(null);

  const [{ basket }, dispatch] = useStateValue();

  // Declaro variables del context
  const { cityZona } = useContext(Context3);
  const [stateCityZona, setStateCityZona] = cityZona;
  const { citySelected } = useContext(Context4);
  const [stateCitySelected, setStateCitySelected] = citySelected;
  const { dateMonth } = useContext(Context5);
  const [stateDateMonth, setStateDateMonth] = dateMonth;
  const mesZona = stateDateMonth + "-" + stateCityZona;
  const { date, rawDate } = useContext(Context);
  const [stateDate, setStateDate] = date;
  const [stateRawDate, setStateRawDate] = rawDate;
  const { resetTienda } = useContext(Context6);
  const [stateResetTienda, setStateResetTienda] = resetTienda;
  const { filtroSeleccionado } = useContext(Context9);
  const [stateFiltroSeleccionado, setStateFiltroSeleccionado] =
    filtroSeleccionado;

  // Almacena el valor de stateFiltroSeleccionado en una variable local, para que funcione en dependencias de useEffect
  const filtroSeleccionadoContext = stateFiltroSeleccionado;

  useEffect(() => {
    setCurrentPage(1);
  }, [filtroSeleccionadoContext]);

  const prevDateRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    if (stateResetTienda === true) {
      setIsLoading(true);
      ResetTienda();
      return; // Salir del useEffect después de llamar a ResetTienda
    }

    const fetchData = async () => {
      try {
        setIsLoading(true);
        let filteredProducts = await filterProducts(sortedProducts);

        setTotalPages(Math.ceil(filteredProducts.length / elementsPerPage));

        await new Promise((resolve) => setTimeout(resolve, 100)); // Simula una tarea asincrónica

        if (stateDate || stateCityZona) {
          await fetchPrices(filteredProducts);
        } else {
          setCurrentElements(filteredProducts.slice(startIndex, endIndex));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Actualiza la referencia mutable con el valor actual de stateDate
    prevDateRef.current = stateDate;
  }, [
    currentPage,
    elementsPerPage,
    endIndex,
    filtroSeleccionadoContext,
    mesZona,
    startIndex,
    stateCityZona,
    stateDate,
    basket,
    stateOrdenSeleccionado,
  ]);

  // Reinicia los estados de la tienda
  const ResetTienda = () => {
    setIsLoading(true);
    setStateDate("");
    setStateCityZona(0);
    setStateRawDate("");
    setStateCitySelected("");
    setCurrentElements((prevElements) =>
      prevElements.map((element) => ({
        ...element,
        price: "-",
        productCardClass: "habilitado",
        disponibleTxt: "⠀",
      }))
    );
    setStateResetTienda(false);
  };

  // FUNCION DE FILTRAR PRODUCTOS
  const filterProducts = async (products) => {
    let filteredProducts = products;

    if (!stateFiltroSeleccionado.includes("todo")) {
      filteredProducts = products.filter((product) =>
        stateFiltroSeleccionado.every((f) => product.filtro.includes(f))
      );
    }

    return filteredProducts;
  };

  // Declara una referencia para almacenar el valor anterior de stateCityZona
  const prevFetchPriceRef = useRef(null);
  const prevCityZonaRef = useRef(null);
  const prevMesZonaRef = useRef(null);
  // Obtiene los precios de los productos
  const fetchPrices = async (filteredProducts) => {
    // Verificar si stateDate es nulo
    if (stateDate === null) {
      setCurrentElements(filteredProducts.slice(startIndex, endIndex));
      return;
    }

    let fetchPrice = null;

    if (stateDate && stateCityZona) {
      if (
        stateCityZona !== prevCityZonaRef.current ||
        mesZona !== prevMesZonaRef.current
      ) {
        // Si stateCityZona ha cambiado, realiza la consulta a la base de datos para obtener fetchPrice actualizado
        const docSnap = await getDoc(doc(db, "PRECIO", mesZona));
        fetchPrice = docSnap.data();

        // Actualiza el valor anterior de stateCityZona almacenado en prevCityZonaRef
        prevCityZonaRef.current = stateCityZona;
        prevMesZonaRef.current = mesZona;
      } else {
        // Si stateCityZona no ha cambiado, utiliza el valor anterior de fetchPrice almacenado en prevFetchPriceRef
        fetchPrice = prevFetchPriceRef.current;
      }
    }

    const fetchPromises = filteredProducts
      .slice(startIndex, endIndex)
      .map((product) => {
        // Retorna una promesa con el producto actualizado con su precio o solo el nombre
        const productData = {
          ...product,
        };

        if (stateCityZona) {
          // Si stateCityZona existe, establece el precio
          const price = fetchPrice[product.id] ? fetchPrice[product.id] : "-";
          productData.price = price;
        }

        return Promise.resolve(productData);
      });

    try {
      // Espera a que se resuelvan todas las promesas y obtiene los productos con sus precios o solo nombres
      const productWithPrices = await Promise.all(fetchPromises);
      setCurrentElements(productWithPrices);

      // Obtiene la disponibilidad de los productos
      await fetchAvailability(filteredProducts);
    } catch (error) {
      console.log("Error fetching prices:", error);
    }

    // Almacena el valor de stateCityZona en prevFetchPriceRef para su uso posterior
    prevFetchPriceRef.current = fetchPrice;
  };

  // Obtiene la disponibilidad de los productos
  const fetchAvailability = async (filteredProducts) => {
    if (stateDate !== null) {
      const fetchPromises = filteredProducts
        .slice(startIndex, endIndex)
        .map((product) => updateProductAvailability(product.item, product.id));

      try {
        // Espera a que se resuelvan todas las promesas y obtiene los productos con su disponibilidad
        const productWithAvailability = await Promise.all(fetchPromises);
        setCurrentElements((prevElements) =>
          prevElements.map((product, index) => ({
            ...product,
            ...productWithAvailability[index],
          }))
        );
      } catch (error) {
        console.log("Error fetching availability:", error);
      }
    } else {
      // Si no hay fecha seleccionada, muestra los productos sin cambios
      setCurrentElements(filteredProducts.slice(startIndex, endIndex));
    }
  };

  // DESHABILITAR CARDS (ponerlas de color GRIS)
  // Declarar una referencia para almacenar el valor previo de docSnap
  const prevStateDate = useRef(null);
  // Crea una referencia mutable para almacenar localDocSnap
  const localDocSnapRef = useRef(null);

  const fetchData = async () => {
    // Verifica si el valor de stateDate ha cambiado
    if (
      stateDate !== prevStateDate.current &&
      stateDate !== null &&
      stateDate !== ""
    ) {
      // Realiza la consulta a Firestore solo si stateDate ha cambiado
      const docRef = doc(db, "RESERVAS", stateDate);
      const docSnap = await getDoc(docRef);
      // Almacena el valor de docSnap en la referencia mutable
      localDocSnapRef.current = docSnap;
      // Al final, actualiza el valor previo de docSnap almacenado en prevDocSnapRef
      prevStateDate.current = stateDate;
    }
  };

  // Realizar la consulta al firestore unicamente cuando cambia el valor de fecha
  useEffect(() => {
    fetchData();
  }, [stateDate]);

  // Lógica para actualizar productCardClass y disponibleTxt
  const updateProductAvailability = async (item, id) => {
    // Declara las variables para almacenar los valores de productCardClass y disponibleTxt
    let productCardClass = "";
    let disponibleTxt = "";

    // Llama a fetchData para actualizar localDocSnapRef.current
    await fetchData();

    // Filtrar los elementos numéricos y los arrays con valores numéricos de la propiedad 'item'
    const numericItems = basket
      .map((item) => item.item)
      .filter(
        (item) =>
          typeof item === "number" ||
          (Array.isArray(item) &&
            item.every((subItem) => typeof subItem === "number"))
      );

    // Unificar todos los valores numéricos en un solo array
    const unifiedArrayItems = numericItems.reduce((acc, item) => {
      if (typeof item === "number") {
        acc.push(item);
      } else if (Array.isArray(item)) {
        acc.push(...item);
      }
      return acc;
    }, []);

    const numericIds = basket
      .map((id) => id.id)
      .filter(
        (id) =>
          typeof id === "number" ||
          (Array.isArray(item) &&
            id.every((subId) => typeof subId === "number"))
      );

    // Unificar todos los valores numéricos en un solo array
    const unifiedArrayIds = numericIds.reduce((acc, id) => {
      if (typeof id === "number") {
        acc.push(id);
      } else if (Array.isArray(id)) {
        acc.push(...id);
      }
      return acc;
    }, []);

    if (stateDate != null) {
      // Si no existe fecha
      if (
        localDocSnapRef.current !== null &&
        localDocSnapRef.current !== undefined &&
        localDocSnapRef.current.exists()
      ) {
        const dataItems = localDocSnapRef.current.data().items || [];
        const dataIds = localDocSnapRef.current.data().ids || [];
        const allItems = unifiedArrayItems.concat(dataItems);

        const isItemAvailable = allItems.some((dataItem) => {
          if (Array.isArray(dataItem)) {
            if (Array.isArray(item)) {
              return dataItem.some((subItem) => item.includes(subItem));
            } else {
              return dataItem.includes(item);
            }
          } else {
            if (Array.isArray(item)) {
              return item.includes(dataItem);
            } else {
              return item === dataItem;
            }
          }
        });

        if (isItemAvailable || dataIds.includes(id)) {
          if (!unifiedArrayIds.includes(id)) {
            // Asigna los valores correspondientes a los atributos
            productCardClass = "deshabilitado";
            disponibleTxt = "No disponible";
          } else {
            // Asigna los valores correspondientes a los atributos
            productCardClass = "habilitado";
            disponibleTxt = "Disponible";
          }
        }
        // Si no existe fecha pero hay productos en el basket
      } else if (basket.length > 0) {
        const isItemAvailable = unifiedArrayItems.some((dataItem) => {
          if (Array.isArray(dataItem)) {
            if (Array.isArray(item)) {
              return dataItem.some((subItem) => item.includes(subItem));
            } else {
              return dataItem.includes(item);
            }
          } else {
            if (Array.isArray(item)) {
              return item.includes(dataItem);
            } else {
              return item === dataItem;
            }
          }
        });

        if (isItemAvailable) {
          if (!unifiedArrayIds.includes(id)) {
            // Asigna los valores correspondientes a los atributos
            productCardClass = "deshabilitado";
            disponibleTxt = "No disponible";
          } else {
            // Asigna los valores correspondientes a los atributos
            productCardClass = "habilitado";
            disponibleTxt = "Disponible";
          }
        }
        // Si no existe fecha ni productos en el basket
      } else {
        // Asigna los valores correspondientes a los atributos
        productCardClass = "habilitado";
        disponibleTxt = "Disponible";
      }
    } else {
      // Asigna los valores correspondientes a los atributos
      productCardClass = "habilitado";
      disponibleTxt = "⠀";
    }

    // Si no se cumple ninguna de las condiciones anteriores, asigna "habilitado"
    if (productCardClass === "") {
      productCardClass = "habilitado";
      disponibleTxt = "Disponible";
    }

    if (unifiedArrayIds.includes(id)) {
      productCardClass = "seleccionado";
      disponibleTxt = "Seleccionado";
    }

    // Retorna un objeto con los atributos actualizados
    return { productCardClass, disponibleTxt };
  };

  useEffect(() => {
    const ultimoElementoResto =
      stateFiltroSeleccionado[stateFiltroSeleccionado.length - 1] === "tematico"
        ? "temático"
        : stateFiltroSeleccionado[stateFiltroSeleccionado.length - 1];

    if (stateFiltroSeleccionado.length <= 1) {
      setNoResultsFiltros(null);
    } else if (stateFiltroSeleccionado.length === 2) {
      setNoResultsFiltros(ultimoElementoResto);
    } else {
      const filteredArray = stateFiltroSeleccionado.slice(
        1,
        stateFiltroSeleccionado.length - 1
      );
      const filteredArrayModificado = filteredArray.map((elemento) => {
        if (elemento === "tematico") {
          return "temáticos";
        }
        return elemento;
      });
      setNoResultsFiltros(
        filteredArrayModificado.join(", ") + " y " + ultimoElementoResto
      );
    }
  }, [currentElements]);

  return (
    <>
      <Box className="tienda-container" padding={2}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "10px" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            className="calendar-city-container"
            style={{ display: "flex" }}
            order={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={12} sm={5.5} md={6} lg={6}>
                <Calendar />
              </Grid>
              <Grid item xs={12} sm={5.5} md={6} lg={5}>
                <City />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={2.8}
            display={{ lg: "block", md: "none", sm: "none", xs: "none" }}
            marginTop={{ lg: "0px", md: "5px", sm: "5px", xs: "5px" }}
            marginBottom={{ lg: "0px", md: "5px", sm: "5px", xs: "5px" }}
            style={{
              display:
                stateDate !== null && stateCityZona !== 0 ? "none" : "flex",
            }}
            order={{ xs: 3, sm: 3, md: 3, lg: 2 }}
          >
            <Alert variant="filled" severity="error" style={{ width: "100%" }}>
              Para ver precios, seleccione fecha y ciudad
            </Alert>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            className="texto-filtro-responsive-container"
            marginBottom={1.9}
            order={{ xs: 3, sm: 3 }}
            display={{ lg: "none", md: "none", sm: "block", xs: "block" }}
          >
            <span className="texto-resaltado-naranja responsive-filtro-texto1">
              {stateFiltroSeleccionado[0] === "todo"
                ? "TODOS LOS PRODUCTOS"
                : stateFiltroSeleccionado[0].toUpperCase()}{" "}
            </span>
            {noResultsFiltros !== null ? (
              <p className="texto-resaltado-naranja responsive-filtro-texto2">
                {noResultsFiltros.toUpperCase()}
              </p>
            ) : null}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            order={{ xs: 4, sm: 4, md: 2, lg: 3 }}
          >
            <Grid
              container
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              }}
            >
              <Grid item>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3.5}
            lg={2.5}
            order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
            display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
          >
            <Filter />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8.5}
            lg={9.5}
            order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "300px", marginTop: "4px" }}
            >
              {isLoading ? (
                <img style={{ width: "300px" }} src={CustomLoadingGif} alt="" />
              ) : (
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="center"
                  className="noSeMuestra"
                >
                  {currentElements.length === 0 ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className="sin-resultados-container"
                      >
                        <Grid
                          item
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <p className="sin-resultados-texto1">
                            No encontramos{" "}
                            <span className="texto-resaltado-dialog">
                              {stateFiltroSeleccionado[0] === "acuaticos"
                                ? "acuáticos"
                                : stateFiltroSeleccionado[0]}
                            </span>{" "}
                            para los filtros aplicados:
                          </p>
                          <p className="sin-resultados-texto1 texto-resaltado-dialog">
                            {noResultsFiltros}
                          </p>
                          <br />
                          <p className="sin-resultados-texto2">
                            Intenta cambiar de filtro...
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    currentElements.map((product) =>
                      product.filtro.includes("acuaticos") ? (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          key={product.id}
                          margin={0}
                        >
                          <ProductCard product={product} />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          key={product.id}
                          margin={0}
                        >
                          <ProductCard product={product} />
                        </Grid>
                      )
                    )
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0} justifyContent="center" marginTop="30px">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Box>
    </>
  );
}
