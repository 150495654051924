import React from "react";
// Material UI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import { ShoppingCart } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
// Estilos
import "./Condiciones.css";
import { useEffect } from "react";
// Iconos
import ParaguaIcon from "../../../assets/images/iconos/paragua.png";
import PapelIcon from "../../../assets/images/iconos/papel.png";
import TerminosYCondicionesIcon from "../../../assets/images/iconos/terminos-y-condiciones.png";
import alertaIcon from "../../../assets/images/iconos/alerta.png";
import relojIcon from "../../../assets/images/iconos/reloj.png";
import billeteIcon from "../../../assets/images/iconos/billete.png";
import prohibidoIcon from "../../../assets/images/iconos/prohibido.png";
// Imagenes
import coberturaImg from "../../../assets/images/otros/cobertura.jpg";
import fechaCiudadImg from "../../../assets/images/tutorial-reserva/fecha-ciudad.jpg";
import reservarBtnImg from "../../../assets/images/tutorial-reserva/reservar-btn.jpg";
import reservarSectionImg from "../../../assets/images/tutorial-reserva/reservar-section.jpg";
// Hooks
import { useHistoryEffects } from "../../../hooks/useHistoryEffects.js";

function Condiciones() {
  // Dialog
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://weatherwidget.io/js/widget.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Tabs para tutorial de reserva
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Cerrar reservarSection con flecha atras
  useHistoryEffects(open, setOpen);
  useHistoryEffects(open2, setOpen2);

  return (
    <>
      {/* MAPA ZONA COBERTURAS */}
      <div className="zonas-de-cobertura">
        <Grid container spacing={0}>
          <Grid item md={1} lg={1.5}></Grid>

          <Grid
            item
            lg={3.6}
            display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
          >
            <h3>ZONAS DE COBERTURA</h3>
            <p>Nos trasladamos a tu casa, salón, SUM o club house.</p>
            <div>
              <ul>
                <li>Quilmes</li>
                <li>Ezpeleta</li>
                <li>Berazategui</li>
                <li>Ranelagh</li>
                <li>Florencio Varela</li>
                <li>Plátanos</li>
                <li>Hudson</li>
                <li>Gutierrez</li>
                <li>Villa Elisa</li>
                <li>City Bell</li>
                <li>Gonnet</li>
              </ul>
              <ul>
                <li>Bernal</li>
                <li>Don Bosco</li>
                <li>Wilde</li>
                <li>Sarandi</li>
                <li>Avellaneda</li>
                <li>Puerto Madero</li>
                <li>Sourigues</li>
                <li>Tolosa</li>
                <li>La Plata</li>
                <li>Ruta 2 kms del 50 al 100</li>
              </ul>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            marginLeft={{ lg: "0px", md: "0px", sm: "0px", xs: "-130px" }}
            style={{ overflow: "hidden" }}
          >
            <img src={coberturaImg} alt="" />
          </Grid>
        </Grid>
      </div>

      {/* SECCION INFORMACION CONDICION PRINCIPAL */}
      <Grid container className="info-container">
        {/* Que pasa si llueve */}
        <Grid className="info-items" item xs={12} sm={6} md={6} lg={4}>
          <img
            style={{ width: "70px", marginBottom: "10px" }}
            src={ParaguaIcon}
            alt=""
          />
          <h4 className="titulo-info">¿Qué pasa si llueve?</h4>
          <p className="subtitulo-info">
            En caso de lluvia se puede suspender hasta las 8 am del día del
            evento y reprogramamos la fecha del evento.
          </p>
        </Grid>
        {/* Como reservar */}
        <Grid className="info-items" item xs={12} sm={6} md={6} lg={4}>
          <img
            style={{ width: "60px", marginBottom: "10px" }}
            src={PapelIcon}
            alt=""
          />
          <h4 className="titulo-info">¿Cómo reservar?</h4>
          <p className="subtitulo-info">
            Tutorial paso a paso de cómo reservar.
          </p>
          <Button
            variant="contained"
            className="leer-mas-btn"
            onClick={() => setOpen2(true)}
          >
            Ver tutorial
          </Button>
        </Grid>
        {/* TERMINOS Y CONDICIONES */}
        <Grid className="mas-info" item xs={12} sm={12} md={12} lg={4}>
          <img
            style={{ width: "60px", marginBottom: "10px" }}
            src={TerminosYCondicionesIcon}
            alt=""
          />
          <h4 className="titulo-info">Términos y condiciones</h4>
          <p className="subtitulo-info">
            A tener en cuenta antes de contratar.
          </p>
          <Button
            variant="contained"
            className="leer-mas-btn"
            onClick={() => setOpen(true)}
          >
            Ver
          </Button>
        </Grid>
      </Grid>

      {/* PRONOSTICO DEL TIEMPO */}
      <a
        className="weatherwidget-io"
        href="https://forecast7.com/es/n34d60n58d38/buenos-aires/"
        data-mode="Forecast"
        data-days="5"
        data-theme="original"
        data-basecolor="#730075"
      >
        .
      </a>

      {/* DIALOG MANUALIZAR AUTOMATIZAR */}
      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
            margin: 0,
          },
        }}
      >
        <DialogActions>
          <Button
            className="cerrar-button"
            onClick={handleClose}
            startIcon={
              <Box
                component="span"
                sx={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ClearIcon />
              </Box>
            }
          >
            Cerrar
          </Button>
        </DialogActions>
        <DialogContent
          sx={{
            padding: (theme) => (theme.breakpoints.down("md") ? 0 : undefined),
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Términos y condiciones
          </DialogTitle>
          <DialogContent>
            {/* Lluvia */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={ParaguaIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  En caso de lluvia se puede suspender hasta las 8 am del día
                  del evento y reprogramamos la fecha del evento.
                </p>
              </Grid>
            </Grid>
            {/* Lluvia y alerta */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Grid container>
                  <Grid item lg={12}>
                    <img
                      className="terminosycondiciones-icons"
                      src={ParaguaIcon}
                      alt=""
                    ></img>
                  </Grid>
                  <Grid item lg={12}>
                    <img
                      className="terminosycondiciones-icons"
                      src={alertaIcon}
                      alt=""
                    ></img>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Si llueve durante el evento se debe apagar el motor y taparlo
                  para que no se queme. Una vez que para la lluvia, se puede
                  volver a prender y usar. Nunca dejar el motor encendido
                  mientras llueva, caso contrario se quemará y se cobrará la
                  reposición del mismo. Valor de multa: $30.000.
                </p>
              </Grid>
            </Grid>
            {/* Alerta */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={alertaIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Si la fecha de reserva del evento supera los 30 dias del
                  momento que se realiza la reserva, se realizará un ajuste en
                  el valor que será informado 15 dias previos al evento.
                </p>
              </Grid>
            </Grid>
            {/* Alerta */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={alertaIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Si cancela el evento a menos de 15 días del comienzo del
                  evento, se debe abonar el 50% de los artículos contratados. En
                  caso de lluvia los depósitos no se reintegran pero se dejan a
                  cuenta para ser utilizados cuando gusten en un futuro evento.
                </p>
              </Grid>
            </Grid>
            {/* Alerta */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={alertaIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Se recomienda que un adulto vigile el uso que los niños le
                  darán a los juegos, Home Kids no se responsabiliza por los
                  accidentes que puedan sufrir los niños.
                </p>
              </Grid>
            </Grid>
            {/* Alerta */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={alertaIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  El material debe entregarse en las mismas condiciones que fue
                  entregado (limpios y sanos).
                </p>
              </Grid>
            </Grid>
            {/* Prohibido */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={prohibidoIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Está prohibido tirar papel picado, serpentina y espuma de
                  colores, dado que los componentes pueden manchar y deteriorar
                  definitivamente la lona del juego. En estos casos se evaluará
                  el daño y se cobrará una multa a definir.
                </p>
              </Grid>
            </Grid>
            {/* Alerta */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={alertaIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Los juegos como el metegol, sapo, tejo, pool, jenga,
                  pasafichas, ping pong y juego de puntería, deben contar con
                  todos los materiales (fichas, pelotita, manopla, paleta,
                  bolas, tacos, triángulo, bolsitas, etc) arriba de la mesa al
                  momento del retiro del juego.
                </p>
              </Grid>
            </Grid>
            {/* Alerta */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={alertaIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Los modelos con peloteros deberán contar con todas sus
                  pelotitas en el interior del pelotero, caso contrario se
                  cobrará una multa de $2.000.
                </p>
              </Grid>
            </Grid>
            {/* Reloj */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={relojIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  El alquiler es por 5 horas desde el horario de inicio del
                  evento (con excepción de quienes contraten horas extra).
                </p>
              </Grid>
            </Grid>
            {/* Billete */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={billeteIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  El pago es en efectivo (sin excepción) a contra entrega el día
                  del evento, saldando lo que reste del depósito en caso de
                  haberlo realizado. <br />
                  El depósito de reserva se puede abonar en efectivo a través de
                  Pago Fácil o Rapipago, tarjetas de crédito o débito, Home
                  Banking, o mediante QR.
                </p>
              </Grid>
            </Grid>
            {/* Prohibido */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={prohibidoIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Está prohibido el ingreso de adultos a los inflables y cama
                  elástica, con excepción de los modelos acuáticos dónde se
                  podrán subir de a uno a la vez.
                </p>
              </Grid>
            </Grid>
            {/* Prohibido */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={prohibidoIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>Está prohibido subir a los inflables con calzado.</p>
              </Grid>
            </Grid>
            {/* Prohibido */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={prohibidoIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Está prohibido mojar el inflable (caso contrario se cobrará
                  una multa), con excepción de que si el modelo que se alquiló
                  es acuático.
                </p>
              </Grid>
            </Grid>
            {/* Prohibido */}
            <Grid container className="text-icons-container">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <img
                  className="terminosycondiciones-icons"
                  src={prohibidoIcon}
                  alt=""
                ></img>
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <p>
                  Está prohibido mover el inflable de lugar. En caso de
                  necesitar hacerlo, le solicitamos comunicarse con nosotros
                  (por cuestiones de seguridad y desperfectos técnicos).
                </p>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>
      </Dialog>

      {/* DIALOG TUTORIAL DE RESERVA*/}
      <Dialog
        disableScrollLock={true}
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
            margin: 0,
          },
        }}
      >
        <DialogActions>
          <Button
            className="cerrar-button"
            onClick={handleClose}
            startIcon={
              <Box
                component="span"
                sx={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ClearIcon />
              </Box>
            }
          >
            Cerrar
          </Button>
        </DialogActions>
        <DialogContent
          sx={{
            padding: (theme) => (theme.breakpoints.down("md") ? 0 : undefined),
          }}
        >
          <DialogTitle id="alert-dialog-title">Tutorial de reserva</DialogTitle>
          <DialogContent>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Paso 1" value="1" />
                  <Tab label="Paso 2" value="2" />
                  <Tab label="Paso 3" value="3" />
                  <Tab label="Paso 4" value="4" />
                  <Tab label="¿Dudas?" value="5" />
                </TabList>
              </Box>
              {/* Paso 1 */}
              <TabPanel value="1">
                <p>
                  Para poder reservar, primero debe elegir la fecha y ciudad de
                  entrega. Recuerde seleccionar su ciudad correctamente, dado
                  que ese va a ser el lugar de entrega para el día del evento.
                </p>
                <br />
                <img width="100%" alt="" src={fechaCiudadImg}></img>
              </TabPanel>
              {/* Paso 2 */}
              <TabPanel value="2">
                <p>
                  Elige el/los producto/s que vaya a reservar para esa fecha y
                  localidad seleccionada. Para ello debe darle click al botón
                  "Reservar" del respectivo producto.
                </p>
                <br />
                <p>
                  Los productos que se presentan de color gris, son aquellos que
                  no están disponibles para la fecha seleccionada.
                </p>
                <br />
                <img width="100%" alt="" src={reservarBtnImg}></img>
              </TabPanel>
              {/* Paso 3 */}
              <TabPanel value="3">
                <p style={{ position: "relative" }}>
                  Se abrirá del lado derecho la sección del carrito, en donde se
                  almacenan los productos elegidos. Puede cerrarlo para seguir
                  eligiendo productos, y volver a abrirlo con el ícono del
                  carrito de compras.
                  <span style={{ position: "absolute", bottom: "-3px" }}>
                    <ShoppingCart fontSize="small" />
                  </span>
                </p>
                <div>
                  <span>
                    En caso de querer quitar de la lista un producto escogido,
                    clickea sobre su respectivo ícono de tacho de basura
                  </span>
                  <span>
                    <DeleteIcon fontSize="small" />
                  </span>
                  <span>en la sección del carrito de compras.</span>
                </div>
                <br />
                <p>
                  Cuando haya escogido los productos deseados para reservar,
                  dele click al botón "Finalizar Reserva".
                </p>
                <br />
                <img width="100%" alt="" src={reservarSectionImg}></img>
              </TabPanel>
              {/* Paso 4 */}
              <TabPanel value="4">
                <p>
                  Será dirigido a un formulario en donde deberá completar sus
                  datos, que serán utilizados como medio de contacto y para el
                  lugar de entrega. Si vive en un country, debe indicar el
                  nombre de éste y la identificación del lote en su respectivo
                  campo de texto.
                </p>
                <br />
                <p>
                  Una vez completado todos los datos requeridos, clickea sobre
                  el botón "Finalizar Reserva", y será redirigido al link de
                  pago de "Pago 360" para poder abonar la seña. Una vez que nos
                  ingresa la reserva y el depósito al sistema, se confirma
                  automáticamente vía mail.
                </p>
              </TabPanel>
              {/* Dudas */}
              <TabPanel value="5">
                <b>
                  ¿Qué pasa si luego de completar el formulario no me
                  redirecciona a un link de pago?
                </b>
                <p>
                  Esto sucede cuando hay alta demanda para la fecha
                  seleccionada, y quedará pendiente a revisión. En caso de que
                  el sistema detecte disponibilidad para la fecha, se le enviará
                  el link de pago de la seña vía mail, con los respectivos pasos
                  a seguir.
                </p>
                <br />
                <b>¿Qué pasa si no tengo la locación definida del evento?</b>
                <p>
                  En ese caso sólo se podrá reservar apenas tengas decidida la
                  fecha, localidad y dirección del evento.
                </p>
                <br />
                <b>
                  ¿Qué pasa si introduzco mal mi mail y/o mi número de teléfono
                  y/o cualquier otro dato al momento de completar el formulario
                  de reserva?
                </b>
                <p>
                  En este caso no habrá medio de comunicación luego de la
                  reserva, por lo que el pedido se cancela automáticamente por
                  sistema.
                </p>
                <br />
                <p>
                  Ante cualquier duda o inconveniente, comuníquese con nosotros,
                  vía{" "}
                  <a href="https://api.whatsapp.com/send/?phone=5491163697737&text&type=phone_number&app_absent=0">
                    whatsapp
                  </a>
                  .
                </p>
                <br />
              </TabPanel>
            </TabContext>
          </DialogContent>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Condiciones;
