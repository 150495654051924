import React from "react";
import { useState, useEffect } from "react";
// Material UI
import esLocale from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Firestore
import {
  doc,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../config";

export default function DíasDeshabilitados() {
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");

  // Se declara una dependencia para el useEffect para recargar el calendario al deshabilitar o habilitar una fecha
  const [updateCalendar, setUpdateCalendar] = useState(false);

  // Se declara estado para guardar valor booleano segun si existe o no fecha seleccionada en firestore
  const [documentoExistente, setDocumentoExistente] = useState();
  // Se declara estado para guardar valor booleano segun si habilitado es true o false en la fecha seleccionada en firestore
  const [isHabilitado, setIsHabilitado] = useState();
  // Se declara estado para guardar valor cantidad de items que hay en la fecha seleccionada en firestore
  const [numeroItems, setNumeroItems] = useState();
  const [dateFormat, setDateFormat] = useState();

  // Dialog
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (fechaSeleccionada !== "") {
      const day = fechaSeleccionada.getDate().toString().padStart(2, "0");
      const month = (fechaSeleccionada.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const year = fechaSeleccionada.getFullYear().toString();

      setDateFormat(`${day}-${month}-${year}`);
    }
  }, [fechaSeleccionada]);

  const verificarExistenciaDocumento = async () => {
    if (fechaSeleccionada !== "") {
      const docRef = doc(db, "RESERVAS", dateFormat);
      try {
        const docSnapshot = await getDoc(docRef);
        const existe = docSnapshot.exists();
        const estaHabilitado =
          existe && docSnapshot.data().habilitado !== undefined
            ? docSnapshot.data().habilitado
            : true;
        const cantItems =
          existe && docSnapshot.data().nroPedidos
            ? docSnapshot.data().nroPedidos.length
            : null;

        setDocumentoExistente(existe);
        setIsHabilitado(estaHabilitado);
        setNumeroItems(cantItems);
      } catch (error) {
        console.log("Error al obtener el documento:", error);
      }
    }
  };

  const HabilitarDeshabilitar = (booleano) => {
    const docRef = doc(db, "RESERVAS", dateFormat);
    if (documentoExistente) {
      // El documento existe
      updateDoc(docRef, {
        habilitado: booleano,
      });
    } else {
      // El documento no existe
      setDoc(docRef, {
        habilitado: booleano,
        limite: parseInt(8),
        automatico: true,
        ids: [],
        items: [],
        nroPedidos: [],
      });
    }
    setUpdateCalendar(!updateCalendar);
    setIsHabilitado(!isHabilitado);
    setOpen(false);
  };

  // Declaro variable para guardar los dias deshabilitados
  const [disableDates, setDisableDates] = useState([]);

  // Se recorre base de datos
  useEffect(() => {
    const obtenerDocumentos = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "RESERVAS"));
        let disableDatesTemp = [];
        let promises = [];

        querySnapshot.forEach((documento) => {
          const fecha = documento.id;
          const habilitado = documento.data().habilitado;

          const promise = habilitado === false ? fecha : null;

          promises.push(promise);
        });

        const results = await Promise.all(promises);
        disableDatesTemp = results.filter(Boolean);

        setDisableDates(disableDatesTemp);
      } catch (error) {
        console.log("Error obteniendo documentos:", error);
      }
    };

    obtenerDocumentos();
  }, [updateCalendar]);

  // Se deshabilita los días en los cuales ya hay 6 reservas
  const DisableDate = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;
    const day1 = props.day.getDate().toString().padStart(2, "0");
    const month2 = (props.day.getMonth() + 1).toString().padStart(2, "0");
    const year3 = props.day.getFullYear().toString();

    const dateFormat = `${day1}-${month2}-${year3}`;

    return (
      <Badge
        overlap="circular"
        badgeContent={disableDates.includes(dateFormat) ? "❌" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={11}>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className="container-diasDeshabilitados"
            >
              <Grid container>
                <Grid item lg={6} marginBottom={2}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esLocale}
                  >
                    <MobileDatePicker
                      label="Seleccione una fecha"
                      value={fechaSeleccionada}
                      disablePast
                      disableHighlightToday
                      onChange={(nuevaFechaSeleccionada) => {
                        setFechaSeleccionada(nuevaFechaSeleccionada);
                      }}
                      onAccept={(nuevaFechaSeleccionada) => {
                        verificarExistenciaDocumento(nuevaFechaSeleccionada);
                      }}
                      slots={{
                        day: DisableDate,
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {fechaSeleccionada !== "" && isHabilitado !== undefined ? (
                    isHabilitado ? (
                      <Button
                        variant="contained"
                        className="rechazar-pedido-btn"
                        onClick={() => setOpen(true)}
                      >
                        Deshabilitar fecha
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="aceptar-pedido-btn"
                        onClick={() => setOpen(true)}
                      >
                        Habilitar fecha
                      </Button>
                    )
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="container-diasDeshabilitados"
            >
              <Grid container alignItems="center" style={{ height: "100%" }}>
                <Grid item lg={12}>
                  <p className="aclaracion-txt">
                    {fechaSeleccionada !== "" && isHabilitado !== undefined
                      ? documentoExistente && numeroItems !== null
                        ? `Hay ${numeroItems} ${
                            numeroItems > 1
                              ? "pedidos registrados"
                              : "pedido registrado"
                          } para la fecha seleccionada`
                        : "No se ha realizado ningún pedido para la fecha seleccionada."
                      : "Seleccione una fecha"}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {(isHabilitado === true ? "Deshabilitar " : "Habilitar ") +
              "día seleccionado"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Está seguro que quiere{" "}
              <span
                className={
                  isHabilitado === true
                    ? "texto-resaltado-dialog-rojo"
                    : "texto-resaltado-dialog-verde"
                }
              >
                {isHabilitado === true ? "deshabilitar" : "habilitar"}
              </span>{" "}
              el día {dateFormat}?<br />
              {dateFormat !== ""
                ? documentoExistente && numeroItems !== null
                  ? `Hay ${numeroItems} ${
                      numeroItems > 1 ? "pedidos" : "pedido"
                    } para esa fecha.`
                  : "No se ha registrado ningún pedido para esa fecha."
                : "Seleccione una fecha"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="button-dialog"
              color="error"
            >
              Cancelar
            </Button>
            {isHabilitado === true ? (
              <Button
                onClick={() => HabilitarDeshabilitar(false)}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            ) : (
              <Button
                onClick={() => HabilitarDeshabilitar(true)}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
