import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import products from "../../../../../../product-data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@mui/material/Grid";
import CustomLoadingGif from "../../../../../../assets/images/otros/loading-pelota.gif";
import CustomLoadingGifVioleta from "../../../../../../assets/images/otros/loading-violeta.gif";

export default function CarouselImages(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0);
  const product = getProductById(props.id); // Obtener el producto por su ID

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [loadedImages, setLoadedImages] = useState([]);

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
  };

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };

  const handleAfterChange = (currentIndex) => {
    setCurrentSlide(currentIndex);
  };

  const settings = {
    customPaging: function (i) {
      const isActive = i === currentSlide;
      if (i === product.carouselImages.length && product.video !== undefined) {
        return (
          <div
            className={`carousel-image-menu-container ${
              isActive ? "active" : ""
            }`}
          >
            <span>
              <img
                className="carousel-image-menu"
                src={require("../../../../../../assets/images/otros/youtube-miniatura.jpg")}
                alt=""
              />
            </span>
          </div>
        );
      } else if (
        i === product.carouselImages.length &&
        product.video === undefined
      ) {
        return <></>;
      }
      return (
        <div
          className={`carousel-image-menu-container ${
            isActive ? "active" : ""
          }`}
        >
          <span>
            {loadedImages.includes(i) ? (
              <img
                className="carousel-image-menu"
                src={product.carouselImages[i]}
                alt=""
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img style={{ width: "100px" }} src={CustomLoadingGif} alt="" />
              </div>
            )}
          </span>
        </div>
      );
    },
    dots: windowWidth > 1200,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: windowWidth > 1200,
    swipe: true,
  };

  function getProductById(id) {
    return products.find((product) => product.id === id);
  }

  if (!product) {
    // Manejar el caso cuando no se encuentra el producto
    return null;
  }

  if (windowWidth < 1200) {
    // Pantallas pequeñas (sm y xs)
    return (
      <div>
        <Grid item className="numero-imagen-slider_txt">
          {currentSlide + 1} / {product.carouselImages.length}
        </Grid>
        <Slider
          {...settings}
          beforeChange={handleBeforeChange}
          afterChange={handleAfterChange}
        >
          {product.carouselImages.map((image, index) => (
            <div className="carousel-container-image-grande" key={index}>
              {loadedImages.includes(index) ? (
                <img className="carousel-image-grande" src={image} alt="" />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    marginTop: "50%",
                  }}
                >
                  <img
                    style={{ width: "300px" }}
                    src={CustomLoadingGifVioleta}
                    alt=""
                  />
                </div>
              )}
              <img
                className="carousel-image-grande"
                src={image}
                alt=""
                onLoad={() => handleImageLoad(index)}
                style={{ display: "none" }}
              />
            </div>
          ))}
        </Slider>
        {product.video !== undefined && (
          <div className="carousel-container-image-grande">{product.video}</div>
        )}
      </div>
    );
  }

  // Pantallas medianas y grandes (md y lg)
  return (
    <div>
      <Grid
        item
        className="numero-imagen-slider_txt"
        sx={{
          display: windowWidth > 1200 ? "none" : "block",
        }}
      >
        {currentSlide + 1} / {product.carouselImages.length + 1}
      </Grid>
      <Slider
        {...settings}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
      >
        {product.carouselImages.map((image, index) => (
          <div className="carousel-container-image-grande" key={index}>
            {loadedImages.includes(index) ? (
              <img className="carousel-image-grande" src={image} alt="" />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  marginTop: "150px",
                }}
              >
                <img
                  style={{ width: "300px" }}
                  src={CustomLoadingGifVioleta}
                  alt=""
                />
              </div>
            )}
            <img
              className="carousel-image-grande"
              src={image}
              alt=""
              onLoad={() => handleImageLoad(index)}
              style={{ display: "none" }}
            />
          </div>
        ))}
        {product.video !== undefined && (
          <div className="carousel-container-image-grande">{product.video}</div>
        )}
      </Slider>
    </div>
  );
}
