import React from "react";
//images
import whatsappIcon from "../../../assets/images/iconos/whatsapp-icon.png";
// Estilos
import "./Whatsapp.css";

function Whatsapp() {
  return (
    <>
      <a href="https://wa.me/5491163697737" rel="noreferrer" target="_blank">
        <img className="whatsapp-icon" src={whatsappIcon} alt=""></img>
      </a>
    </>
  );
}

export default Whatsapp;
