import React from "react";
// Material UI
import Grid from "@mui/material/Grid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
// Estilos
import "./Contacto.css";

function Contacto() {
  return (
    <>
      <Grid className="contacto" container spacing={0}>
        <Grid item lg={1.5}></Grid>
        <Grid item lg={5}>
          <h3>CONTACTO</h3>
          <h4>NOS ENCONTRAMOS EN QUILMES</h4>
          <span>Días y horarios de atención:</span>
          <p>Lunes a viernes de 9 a 13 y 15 a 19hs.</p>
          <p>Sábados y domingos sólo se reciben consultas vía WhatsApp.</p>
          <Grid marginTop={3} container spacing={0}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <a
                href="https://wa.me/5491163697737"
                rel="noreferrer"
                target="_blank"
              >
                <WhatsAppIcon className="icon" /> 9 11 6369-7737
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <a
                href="https://www.instagram.com/homekidsfiestas/"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon className="icon" /> homekidsfiestas
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <a href="mailto:info@homekids.com.ar">
                <MailOutlineIcon className="icon" /> info@homekids.com.ar
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <a
                href="https://www.facebook.com/homekidsfiestas/"
                rel="noreferrer"
                target="_blank"
              >
                <FacebookIcon className="icon" /> homekidsfiestas
              </a>
            </Grid>
            <Grid item xs={0} sm={6} md={6} lg={6}></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <a
                href="https://www.youtube.com/channel/UC9izEVL-BxZLf13b0rAuKPg"
                rel="noreferrer"
                target="_blank"
              >
                <YouTubeIcon className="icon" /> homekidsfiestas
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Contacto;
