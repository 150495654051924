import React from "react";
// Material UI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Hooks
import { useStateValue } from "../../../../../hooks/StateProvider";
import { actionTypes } from "../../../../../reducer";
import { useHistoryEffects } from "../../../../../hooks/useHistoryEffects.js";
// Context
import { useContext, useState } from "react";
import { Context3, Context4 } from "../../../../../context/Context";

function City() {
  const cities = [
    { value: "Arturo Segui", label: "Z4" },
    { value: "Avellaneda Centro", label: "Z3" },
    { value: "Berazategui", label: "Z2" },
    { value: "Bernal", label: "Z2" },
    { value: "City Bell", label: "Z4" },
    { value: "Don Bosco", label: "Z2" },
    { value: "El Peligro", label: "Z4" },
    { value: "El Pato", label: "Z3" },
    { value: "Ezpeleta", label: "Z1" },
    { value: "F. Varela Centro", label: "Z3" },
    { value: "Gonnet", label: "Z4" },
    { value: "Gutierrez", label: "Z2" },
    { value: "Hudson", label: "Z2" },
    { value: "La Capilla", label: "Z5" },
    { value: "Pereyra", label: "Z2" },
    { value: "Plátanos", label: "Z2" },
    { value: "Puerto Madero", label: "Z5" },
    { value: "Quilmes", label: "Z1" },
    { value: "Quilmes Oeste", label: "Z1" },
    { value: "Ranelagh", label: "Z2" },
    { value: "Ruta 2 km 40-49", label: "Z4" },
    { value: "Ruta 2 km 50-80", label: "Z5" },
    { value: "Villa Elisa", label: "Z3" },
    { value: "Wilde", label: "Z2" },
  ];

  // Declaro variables del context
  const { cityZona } = useContext(Context3);
  const [stateCityZona, setStateCityZona] = cityZona;
  const { citySelected } = useContext(Context4);
  const [stateCitySelected, setStateCitySelected] = citySelected;
  const [citySelectedTemp, setCitySelectedTemp] = useState();
  const [cityZonaTemp, setCityZonaTemp] = useState();

  const [city, setCity] = React.useState(null);
  // Dialog
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  // Llamo constante de array con productos elegidos
  const [{ basket }, dispatch] = useStateValue();

  const cityChange = () => {
    // Cambia la ciudad seleccionada
    setStateCityZona(cityZonaTemp);
    setStateCitySelected(citySelectedTemp);
    setCity(citySelectedTemp);
    setOpen(false);

    // Elimina los productos del carrito
    basket.forEach((product) => {
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        id: product.id,
      });
    });
  };

  const handleChange = (e) => {
    // Guardar nombre de ciudad en context
    const selectedValue = e.target.value;
    const selectedCity = cities.find((city) => city.value === selectedValue);
    setCityZonaTemp(selectedCity.label);
    setCitySelectedTemp(selectedCity.value);
    if (basket.length > 0) {
      setOpen(true);
    } else {
      setCity(e.target.value);

      // Guardar el precio por ciudad en context
      setStateCityZona(selectedCity.label); // obtener la etiqueta desde el objeto seleccionado

      setStateCitySelected(selectedCity.value);
    }
  };

  // Cerrar dialog con flecha atras
  useHistoryEffects(open, setOpen);

  return (
    <>
      <Box>
        <FormControl
          className={stateCityZona === 0 ? "inputRed" : null}
          sx={{
            "& .MuiInputBase-root": {
              color: "white",
              borderColor: "white",
            },
            "& .MuiInputLabel-root": {
              color: "white",
              "&.Mui-focused": {
                color: "white",
              },
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "white",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "white",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "white",
            },
            "& input[disabled]": {
              color: "rgba(255, 255, 255, 0.6)",
            },
          }}
          fullWidth
        >
          <InputLabel id="select-label">Ciudad</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={stateCitySelected}
            label="Ciudad"
            onChange={handleChange}
          >
            {cities.map((city) => (
              <MenuItem key={city.value} value={city.value}>
                {city.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {"Cambiar ciudad de entrega"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Si seleccionas otra ciudad, los productos que hayas agregado al
              carrito para la ciudad actual serán eliminados. ¿Deseas continuar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button onClick={cityChange} autoFocus color="success">
              Aceptar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default City;
