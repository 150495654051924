import React from "react";
// Material UI
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import { Typography } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CircularProgress from "@mui/material/CircularProgress";

// Firestore
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config";
// Hooks
import { useState } from "react";
import { useStateValue } from "../../hooks/StateProvider";
import { useEffect } from "react";
import { actionTypes } from "../../reducer";
// Context
import { useContext } from "react";
import {
  Context,
  Context4,
  Context6,
  Context7,
  Context8,
} from "../../context/Context";
// Styles
import "./FormPayment.css";
// Components
import Header from "./Header";
import ProductSelectedName from "../Inicio/Tienda/components/ReservarSection/ProductsSelected/components/ProductSelectedName";
import ProductSelectedPrice from "../Inicio/Tienda/components/ReservarSection/ProductsSelected/components/ProductSelectedPrice";
import ProductSelectedImage from "../Inicio/Tienda/components/ReservarSection/ProductsSelected/components/ProductSelectedImage";
// Hooks
import { Link } from "react-router-dom";
// Data
import products from "../../product-data";

function FormPayment() {
  const [{ basket }, dispatch] = useStateValue();
  // Obtener la fecha almacenada en el context
  const { date, rawDate } = useContext(Context);
  const [stateDate, setStateDate] = date;
  const [stateRawDate, setStateRawDate] = rawDate;
  // Obtener nombre de localidad almacenad en el context
  const { citySelected } = useContext(Context4);
  const [stateCitySelected, setStateCitySelected] = citySelected;
  // Resetear estados de Tienda
  const { resetTienda } = useContext(Context6);
  const [stateResetTienda, setStateResetTienda] = resetTienda;
  // Obtener precio minimo
  const { precioMinimo } = useContext(Context7);
  const [statePrecioMinimo, setStatePrecioMinimo] = precioMinimo;
  // Obtener si el acceso a Payment
  const { accesoPayment } = useContext(Context8);
  const [stateAccesoPayment, setStateAccesoPayment] = accesoPayment;
  // Variables por cada input del formulario
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [mail, setMail] = useState("");
  const [horario, setHorario] = useState("");
  const [dni, setDni] = useState("");
  const [lote, setLote] = useState("");
  const [seña, setSeña] = useState(0);
  const [idpago, setIdpago] = useState("");
  const [url, setUrl] = useState("");
  // Variables de estado

  const [precio, setPrecio] = useState(0);
  const [señaCambiada, setSeñaCambiada] = useState(false);
  const [urlCambiada, setUrlCambiada] = useState(false);
  const [idpagoCambiada, setIdpagoCambiada] = useState(false);
  const [reservaFinalizada, setReservaFinalizada] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showResumenResponsive, setShowResumenResponsive] = useState(false);

  const handleShowResumenResponsive = () => {
    setShowResumenResponsive(!showResumenResponsive);
  };

  // Estado que almacena los datos de los producos coincidentes ya reservados
  const [productosCoincidentes, setProductosCoincidentes] = useState([]);
  // Variable dia en 48hs
  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + 2);
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const pasadoMañana = formatDate(futureDate).toString();

  const [basketTemp, setBasketTemp] = useState(basket);

  // Obtener diferencia entre dia de hoy y dia escogido para la reserva
  const differenceInMilliseconds = Math.abs(today - stateRawDate);
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // POner fondo del body de color verde
  useEffect(() => {
    document.body.style.backgroundColor = "#3be200";
  }, []);

  useEffect(() => {
    let total = 0;

    basket.forEach((product) => {
      total += product.price;
    });

    setPrecio(total);
  }, [basket]);

  // Dialog
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => {
    dispatch({
      type: actionTypes.EMPTY_BASKET,
    });
    setOpen(false);
    setStateResetTienda(true);
    document.body.style.overflow = "auto";
  };

  const handleClose2 = () => {
    setOpen2(false);
    setLoading(false);
  };

  const cambiarNombreDocumento = async (docRef, nuevoPedido) => {
    await updateDoc(docRef, {
      numero: nuevoPedido,
    });
  };

  const precioTotalNeto = basketTemp
    .reduce((amount, item) => amount + item.price, 0)
    .toLocaleString()
    .replace(",", ".");

  const Reservar = async () => {
    const ids = [];
    const items = [];
    const especificaciones = basket.map((product) => product.name).join(" - ");
    const filtro = basket.map((product) => product.filtro);
    const filtros = [].concat(...filtro);

    basket.forEach((product) => {
      ids.push(product.id);
      if (typeof product.item === "number") {
        items.push(product.item);
      } else if (Array.isArray(product.item)) {
        items.push(...product.item);
      }
    });

    // Recolecar numero de pedidos
    const docRefNro = await doc(db, "NRO_PEDIDO", "NRO_PEDIDO");
    const docSnapNro = await getDoc(docRefNro);
    const dataNro = docSnapNro.exists() ? docSnapNro.data() : {};
    const numeroPedido = dataNro.numero;
    const nuevoPedido = numeroPedido + 1;

    // Agregar numero de items presentes en la fecha seleccionada en su atributo pedidos
    const docRef = doc(db, "RESERVAS", stateDate);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : {};

    // Verificar que si otra persona haya reservado uno de los inflables escogidos
    const coincidentValues1 =
      docSnap.exists() && data.ids
        ? ids?.filter((id) => data.ids.includes(id)) || []
        : [];
    if (coincidentValues1.length > 0 && data.hasOwnProperty("ids")) {
      const coincidentValuesIds = data.ids;
      const coincidentValues = ids
        .filter((id) => coincidentValuesIds.includes(id))
        .map((id) => products.find((product) => product.id === id).name);
      const totalIdsLengthCoincident = ids.filter((id) =>
        coincidentValuesIds.includes(id)
      ).length;

      const restoProductos = ids
        .filter((id) => !coincidentValuesIds.includes(id))
        .map((id) => products.find((product) => product.id === id).name);
      const totalIdsLengthResto = ids.filter(
        (id) => !coincidentValuesIds.includes(id)
      ).length;

      const precioTotal = basket.reduce(
        (total, product) => total + parseFloat(product.price),
        0
      );
      const precioTotalCoincident = basket
        .filter((product) => coincidentValuesIds.includes(product.id))
        .reduce((total, product) => total + product.price, 0);

      let resultadoCoincidentes = "";
      if (coincidentValues.length === 1) {
        resultadoCoincidentes = coincidentValues[0];
      } else {
        const ultimoElementoCoincidentes = coincidentValues.pop();
        resultadoCoincidentes =
          coincidentValues.join(", ") + " y " + ultimoElementoCoincidentes;
      }

      let resultadoResto = "";
      if (restoProductos.length === 1) {
        resultadoResto = restoProductos[0];
      } else {
        const ultimoElementoResto = restoProductos.pop();
        resultadoResto =
          restoProductos.join(", ") + " y " + ultimoElementoResto;
      }

      const productoConcatenado = {
        coincidentes: resultadoCoincidentes,
        resto: resultadoResto,
        precio_total: precioTotal,
        precio_total_coinciden: precioTotalCoincident,
        cant_coincidentes: totalIdsLengthCoincident,
        cant_resto: totalIdsLengthResto,
      };
      setProductosCoincidentes([productoConcatenado]);

      // Se eliminan los productos no disponibles del basket
      data.ids.map((id) =>
        dispatch({
          type: actionTypes.REMOVE_ITEM,
          id: id,
        })
      );
      setOpen2(true);
    } else {
      let currentIds = [];
      if (data.hasOwnProperty("ids")) {
        currentIds = Array.isArray(data.ids) ? data.ids : [data.ids];
      }
      let currentItems = [];
      if (data.hasOwnProperty("items")) {
        currentItems = Array.isArray(data.items) ? data.items : [data.items];
      }
      let currentNroPedidos = [];
      if (data.hasOwnProperty("nroPedidos")) {
        currentNroPedidos = Array.isArray(data.nroPedidos)
          ? data.nroPedidos
          : [data.nroPedidos];
      }

      const newIds = [...currentIds, ...ids]; // Concatenar los nuevos ids
      const newItems = [...currentItems, ...items]; // Concatenar los nuevos items
      const newNroPedidos = [...currentNroPedidos, nuevoPedido]; // Concatenar los nuevos nroPedidos

      if (docSnap.exists()) {
        if (data.hasOwnProperty("nroPedidos")) {
          await updateDoc(docRef, {
            ids: newIds,
            items: newItems,
            nroPedidos: newNroPedidos,
          });
        } else {
          await setDoc(docRef, {
            ids: newIds,
            items: newItems,
            nroPedidos: newNroPedidos,
            habilitado: data.hasOwnProperty("habilitado")
              ? data.habilitado
              : true,
            automatico: data.hasOwnProperty("automatico")
              ? data.automatico
              : true,
            limite: data.limite,
          });
        }
      } else {
        await setDoc(docRef, {
          ids: newIds,
          items: newItems,
          nroPedidos: newNroPedidos,
          habilitado: true,
          automatico: true,
          limite: 8,
        });
      }
      // Verifica si es reserva automatico
      if (docSnap.exists()) {
        if (
          data.automatico &&
          parseInt(data.items.length) + ids.length < parseInt(data.limite) &&
          !filtros.includes("livings") &&
          !(stateCitySelected === "Quilmes") &&
          !(stateCitySelected === "Quilmes Oeste") &&
          !(stateCitySelected === "Ezpeleta") &&
          !ids.includes(44) &&
          !ids.includes(45) &&
          !ids.includes(47) &&
          !ids.includes(125) &&
          !ids.includes(126) &&
          differenceInDays > 3
        ) {
          const promises = [
            setDoc(
              doc(db, "RESERVAS", stateDate, nuevoPedido.toString(), "Datos"),
              {
                A1DNI: dni,
                A2nombre: nombre,
                A3apellido: apellido,
                A4fecha: stateDate,
                A5especificaciones: especificaciones,
                A6nroPedido: nuevoPedido,
                A7localidad: stateCitySelected,
                A8direccion: direccion,
                A9lote: lote,
                B1horario: horario,
                B2precio: precio,
                B3seña: seña,
                B4telefono: telefono,
                B5mail: mail,
                B6aprobado: true,
                B7rechazado: false,
                B8manual: false,
                B9cargado: false,
                C1idpago: idpago,
                C2url: url,
                C3ids: ids,
                C4items: items,
              }
            ),
          ];
          Promise.all(promises).then(() => {
            setDisabled(true);
            setLoading(false);
            dispatch({
              type: actionTypes.EMPTY_BASKET,
            });
            cambiarNombreDocumento(docRefNro, nuevoPedido);

            setStateResetTienda(true);
            window.location.href = url;
          }); // Verifica si es reserva manual
        } else {
          setDoc(
            doc(db, "RESERVAS", stateDate, nuevoPedido.toString(), "Datos"),
            {
              A1DNI: dni,
              A2nombre: nombre,
              A3apellido: apellido,
              A4fecha: stateDate,
              A5especificaciones: especificaciones,
              A6nroPedido: nuevoPedido,
              A7localidad: stateCitySelected,
              A8direccion: direccion,
              A9lote: lote,
              B1horario: horario,
              B2precio: precio,
              B3seña: seña,
              B4telefono: telefono,
              B5mail: mail,
              B6aprobado: false,
              B7rechazado: false,
              B8manual: false,
              B9cargado: false,
              C1idpago: idpago,
              C2url: url,
              C3ids: ids,
              C4items: items,
            }
          );
          setLoading(false);
          setDisabled(true);
          setOpen(true);
          dispatch({
            type: actionTypes.EMPTY_BASKET,
          });
          cambiarNombreDocumento(docRefNro, nuevoPedido);
          setStateResetTienda(true);
        }
        // Si no existe fecha, es decir, aun no existen reservas para ese día
      } else {
        // Verifica si es reserva automatico
        if (
          !filtros.includes("livings") &&
          !(stateCitySelected === "Quilmes") &&
          !(stateCitySelected === "Quilmes Oeste") &&
          !(stateCitySelected === "Ezpeleta") &&
          !ids.includes(44) &&
          !ids.includes(45) &&
          !ids.includes(47) &&
          !ids.includes(125) &&
          !ids.includes(126) &&
          differenceInDays > 3
        ) {
          const promises = [
            setDoc(
              doc(db, "RESERVAS", stateDate, nuevoPedido.toString(), "Datos"),
              {
                A1DNI: dni,
                A2nombre: nombre,
                A3apellido: apellido,
                A4fecha: stateDate,
                A5especificaciones: especificaciones,
                A6nroPedido: nuevoPedido,
                A7localidad: stateCitySelected,
                A8direccion: direccion,
                A9lote: lote,
                B1horario: horario,
                B2precio: precio,
                B3seña: seña,
                B4telefono: telefono,
                B5mail: mail,
                B6aprobado: true,
                B7rechazado: false,
                B8manual: false,
                B9cargado: false,
                C1idpago: idpago,
                C2url: url,
                C3ids: ids,
                C4items: items,
              }
            ),
          ];
          Promise.all(promises).then(() => {
            setDisabled(true);
            setLoading(false);
            dispatch({
              type: actionTypes.EMPTY_BASKET,
            });
            cambiarNombreDocumento(docRefNro, nuevoPedido);
            setStateResetTienda(true);
            window.location.href = url;
          });
        } else {
          // Verifica si es reserva manual
          setDoc(
            doc(db, "RESERVAS", stateDate, nuevoPedido.toString(), "Datos"),
            {
              A1DNI: dni,
              A2nombre: nombre,
              A3apellido: apellido,
              A4fecha: stateDate,
              A5especificaciones: especificaciones,
              A6nroPedido: nuevoPedido,
              A7localidad: stateCitySelected,
              A8direccion: direccion,
              A9lote: lote,
              B1horario: horario,
              B2precio: precio,
              B3seña: seña,
              B4telefono: telefono,
              B5mail: mail,
              B6aprobado: false,
              B7rechazado: false,
              B8manual: false,
              B9cargado: false,
              C1idpago: idpago,
              C2url: url,
              C3ids: ids,
              C4items: items,
            }
          );
          setLoading(false);
          setDisabled(true);
          setOpen(true);
          dispatch({
            type: actionTypes.EMPTY_BASKET,
          });
          cambiarNombreDocumento(docRefNro, nuevoPedido);
          setStateResetTienda(true);
        }
      }
    }
  };

  const Pagar = async () => {
    const cuerpo =
      '{"payment_request":{"description":"Reserva evento","first_due_date":"' +
      pasadoMañana +
      '","first_total":"' +
      seña +
      '","payer_name":"' +
      nombre +
      " " +
      apellido +
      '","back_url_success":"https://homekids.com.ar/payment/finalizado","back_url_pending":"https://homekids.com.ar/payment/finalizado","excluded_installments":[3,6,9,12]}}';
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer YmE0YmUwNmVmN2UwNzI0MmIyM2E1Njc2NzZhYmZjNGMwZTBkYTc0MmI0YjA4N2YyNGI3MzRlNDgzZTU0NGUwZA",
      },
      body: cuerpo,
    };

    const response = await fetch(
      "https://api.pagos360.com/payment-request",
      requestOptions
    );
    const data = await response.json();
    setIdpago(data.id);
    setUrl(data.checkout_url);
    setIdpagoCambiada(true);
    setUrlCambiada(true);
  };

  const [inputs, setInputs] = useState({
    inputMail: { value: mail, error: false, helperText: "" },
    inputNombre: { value: nombre, error: false, helperText: "" },
    inputApellido: { value: apellido, error: false, helperText: "" },
    inputDNI: { value: dni, error: false, helperText: "" },
    inputTelefono: { value: telefono, error: false, helperText: "" },
    inputDireccion: { value: direccion, error: false, helperText: "" },
    inputHorario: { value: horario || "", error: false, helperText: "" },
  });

  const handleInputChange = (event, index, inputHorario) => {
    const { id, value } = event.target;

    // Actualizar el estado local correspondiente
    switch (id) {
      case "inputMail":
        setMail(value);
        break;
      case "inputNombre":
        setNombre(value);
        break;
      case "inputApellido":
        setApellido(value);
        break;
      case "inputDNI":
        setDni(value);
        break;
      case "inputTelefono":
        setTelefono(value);
        break;
      case "inputDireccion":
        setDireccion(value);
        break;

      default:
        break;
    }
    if (inputHorario) {
      setHorario(value);
    }

    // Actualizar el estado `inputs` con los nuevos valores
    setInputs((prevState) => {
      const updatedInputs = { ...prevState }; // Copiar el objeto previo
      const inputKey = Object.keys(prevState)[index]; // Obtener la clave del input

      updatedInputs[inputKey] = {
        ...updatedInputs[inputKey],
        value,
        error: false,
        helperText: "",
      };

      return updatedInputs;
    });
  };

  // Función para verificar si los campos de texto están completos
  const validarCampos = () => {
    let camposCompletos = true;
    const emailRegex = /^[A-Za-z0-9_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    // Verificar cada campo de texto
    const updatedInputs = Object.entries(inputs).map(([key, input]) => {
      const trimmedValue = input.value.trim();
      if (trimmedValue === "") {
        camposCompletos = false;
        setLoading(false);
        return {
          [key]: { ...input, error: true, helperText: "Campo incompleto" },
        };
      } else if (key === "inputMail") {
        if (!emailRegex.test(trimmedValue)) {
          camposCompletos = false;
          setLoading(false);
          return {
            [key]: {
              ...input,
              error: true,
              helperText: "Correo electrónico inválido",
            },
          };
        } else if (trimmedValue.includes("ñ")) {
          return {
            [key]: {
              ...input,
              error: true,
              helperText: "No se permite la letra 'ñ'",
            },
          };
        } else {
          return { [key]: { ...(input || {}), error: false, helperText: "" } };
        }
      } else {
        return { [key]: { ...(input || {}), error: false, helperText: "" } };
      }
    });

    const updatedInputsObject = Object.assign({}, ...updatedInputs);
    setInputs(updatedInputsObject);

    return camposCompletos;
  };

  // Función para manejar el clic en el botón "Finalizar Reserva"
  const handleFinalizarReserva = () => {
    setLoading(true);

    const camposCompletos = validarCampos();

    if (camposCompletos) {
      Conjunto();
      setReservaFinalizada(true); // Establece el estado de reservaFinalizada en true al hacer clic en el botón
    }
  };

  // Efecto para validar el cambio de señas y ejecutar Pagar()
  useEffect(() => {
    if (señaCambiada && reservaFinalizada) {
      Pagar();
      setSeñaCambiada(false);
    }
  }, [señaCambiada, reservaFinalizada]);

  useEffect(() => {
    if (idpagoCambiada && urlCambiada) {
      Reservar();
      setIdpagoCambiada(false);
      setUrlCambiada(false);
    }
  }, [idpagoCambiada, urlCambiada]);

  // Funcion conjunta
  const Conjunto = () => {
    if (precio < 15000) {
      setSeña(3000);
    } else if (precio > 50000) {
      setSeña(10000);
    } else {
      setSeña(Math.round(precio * 0.0002) * 1000);
    }
    setSeñaCambiada(true);
  };

  return (
    <>
      <Header />
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={11}
          sm={11}
          md={11}
          lg={11}
          marginTop={17}
          marginBottom={{ xs: 15, sm: 15, md: 0, lg: 0 }}
        >
          <p
            className="advertencia-text"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ReportGmailerrorredIcon style={{ marginRight: "10px" }} />
            Recuerde completar correctamente tus datos, dado que es nuestra
            forma de comunicarnos con usted
          </p>
          <p
            className="advertencia-text"
            style={{ display: "flex", alignItems: "center" }}
          >
            <AirportShuttleIcon style={{ marginRight: "10px" }} />
            Costo de envío bonificado
          </p>

          <Grid container justifyContent="space-between">
            {/* DESDE ACA */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8.5}
              sx={{
                "& .MuiInputBase-root": {
                  color: "white",
                  borderColor: "white",
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                  "&.Mui-focused": {
                    color: "white",
                  },
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "white",
                },
                "& input[disabled]": {
                  color: "rgba(255, 255, 255, 0.6)",
                },
              }}
              noValidate
              autoComplete="off"
              marginTop={2}
            >
              <Grid
                container
                className="container-inputs"
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => handleInputChange(e, 0)}
                    error={inputs.inputMail.error}
                    helperText={inputs.inputMail.helperText}
                    id="inputMail"
                    label="Mail"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => handleInputChange(e, 1)}
                    error={inputs.inputNombre.error}
                    helperText={inputs.inputNombre.helperText}
                    id="inputNombre"
                    label="Nombre"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => handleInputChange(e, 2)}
                    error={inputs.inputApellido.error}
                    helperText={inputs.inputApellido.helperText}
                    id="inputApellido"
                    label="Apellido"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => handleInputChange(e, 3)}
                    error={inputs.inputDNI.error}
                    helperText={inputs.inputDNI.helperText}
                    id="inputDNI"
                    label="DNI"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => handleInputChange(e, 4)}
                    error={inputs.inputTelefono.error}
                    helperText={inputs.inputTelefono.helperText}
                    id="inputTelefono"
                    label="Teléfono"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <FormControl
                    variant="standard"
                    styles={{
                      root: { borderColor: "white" }, // Borde
                    }}
                    fullWidth
                    error={inputs.inputHorario.error === true ? true : false}
                  >
                    <InputLabel id="inputHorario">Horario</InputLabel>
                    <Select
                      value={horario}
                      id="inputHorario"
                      label="Horario"
                      onChange={(e) => handleInputChange(e, 6, true)}
                    >
                      <MenuItem value="11hs">11-16hs</MenuItem>
                      <MenuItem value="12hs">12-17hs</MenuItem>
                      <MenuItem value="13hs">13-18hs</MenuItem>
                      <MenuItem value="14hs">14-19hs</MenuItem>
                      <MenuItem value="15hs">15-20hs</MenuItem>
                      <MenuItem value="16hs">16-21hs</MenuItem>
                      <MenuItem value="17hs">17-22hs</MenuItem>
                      <MenuItem value="18hs">18-23hs</MenuItem>
                      <MenuItem value="19hs">19-00hs</MenuItem>
                      <MenuItem value="20hs">20-01hs</MenuItem>
                      <MenuItem value="21hs">21-02hs</MenuItem>
                    </Select>
                    <FormHelperText>
                      {inputs.inputHorario.error === true
                        ? "Error: Campo incompleto"
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    disabled
                    id="inputLocalidad"
                    label="Localidad"
                    defaultValue={stateCitySelected}
                    variant="standard"
                  />
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => handleInputChange(e, 5)}
                    error={inputs.inputDireccion.error}
                    helperText={inputs.inputDireccion.helperText}
                    id="inputDireccion"
                    label="Dirección / Country"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={11.9}
                  sm={5.9}
                  md={3.9}
                  lg={3.9}
                  marginBottom={5}
                >
                  <TextField
                    fullWidth
                    onChange={(e) => setLote(e.target.value)}
                    id="inputLote"
                    label="Número de Lote"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <Grid container justifyContent={"center"} alignItems="center">
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item>
                        <FormHelperText error>
                          {inputs.inputMail.error === true ||
                          inputs.inputNombre.error === true ||
                          inputs.inputApellido.error === true ||
                          inputs.inputDNI.error === true ||
                          inputs.inputTelefono.error === true ||
                          inputs.inputDireccion.error === true ||
                          inputs.inputHorario.error === true
                            ? "Hay campos incorrectos"
                            : ""}
                        </FormHelperText>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          size="large"
                          className={
                            disabled
                              ? "finalizar-reserva-button-payment-disabled"
                              : "finalizar-reserva-button-payment"
                          }
                          variant="contained"
                          onClick={handleFinalizarReserva}
                          disabled={disabled}
                        >
                          {loading && (
                            <CircularProgress
                              size={20}
                              style={{ marginRight: "8px" }}
                            />
                          )}
                          {loading ? "Cargando" : "Finalizar Reserva"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*  RESUMEN NORMAL */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              marginTop={2}
              display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
            >
              <Grid container className="container-inputs">
                <p className="date-payment">
                  Reservando para el día: {stateDate}
                </p>
                {basketTemp.map((item) => (
                  <Grid key={item.id} item xs={12} sm={12} md={12} lg={12}>
                    <div className="contenedorComponents">
                      <div className="products-name">
                        {/* Nombre */}
                        <ProductSelectedName name={item.name} />
                      </div>
                      <Box flexGrow="1"></Box>
                    </div>

                    <div className="contenedorComponents">
                      <div className="contenedorImage">
                        {/* Imagen */}
                        <ProductSelectedImage
                          image={item.image}
                          name={item.name}
                        />
                      </div>
                      <Box flexGrow="1"></Box>
                      <div className="products-price">
                        {/* Precio */}
                        <ProductSelectedPrice price={item.price} />
                      </div>
                    </div>
                    <Divider style={{ backgroundColor: "white" }} />
                  </Grid>
                ))}
                <Box
                  display="flex"
                  alignItems="center"
                  fontFamily={"Montserrat"}
                  fontSize={"26px"}
                  className="products-price"
                  width={"100%"}
                >
                  Total:
                  <Box flexGrow="1"></Box>
                  <Typography
                    variant="inherit"
                    component="p"
                    className="valor-total"
                  >
                    $ {precioTotalNeto}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* RESUMEN RESPONSIVE */}
      <div style={{ zIndex: "910" }}>
        <Grid
          container
          display={{ lg: "none", md: "none", sm: "block", xs: "block" }}
          className="button-resumen-responsive-container"
          justifyContent="center"
          alignItems="center"
          onClick={handleShowResumenResponsive}
        >
          <Grid
            item
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              <span className="total-resumen-responsive_txt">
                {showResumenResponsive ? null : "TOTAL"}
              </span>
              <span className="precio-total-resumen-responsive_txt">
                {showResumenResponsive ? null : `$ ${precioTotalNeto}`}
              </span>
            </div>
            <Button
              className="button-resumen-responsive_txt"
              style={{ marginTop: showResumenResponsive ? null : "2rem" }}
            >
              {showResumenResponsive ? `Cerrar ` : `Ver resumen `}
              {showResumenResponsive ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          display={{ lg: "none", md: "none", sm: "block", xs: "block" }}
          className={`resumen-responsive  ${showResumenResponsive && "active"}`}
        >
          <Grid item xs={12} sm={12} md={12} lg={3} marginTop={2}>
            <Grid container className="container-inputs">
              <p className="date-payment">Reservando para el día:</p>
              <p className="date-payment">{stateDate}</p>
              {basketTemp.map((item) => (
                <Grid key={item.id} item xs={12} sm={12} md={12} lg={12}>
                  <div className="contenedorComponents">
                    <div className="products-name">
                      {/* Nombre */}
                      <ProductSelectedName name={item.name} />
                    </div>
                    <Box flexGrow="1"></Box>
                  </div>

                  <div className="contenedorComponents">
                    <div className="contenedorImage">
                      {/* Imagen */}
                      <ProductSelectedImage
                        image={item.image}
                        name={item.name}
                      />
                    </div>
                    <Box flexGrow="1"></Box>
                    <div className="products-price">
                      {/* Precio */}
                      <ProductSelectedPrice price={item.price} />
                    </div>
                  </div>
                  <Divider style={{ backgroundColor: "white" }} />
                </Grid>
              ))}
              <Box
                display="flex"
                alignItems="center"
                fontFamily={"Montserrat"}
                fontSize={"26px"}
                className="products-price"
                width={"100%"}
              >
                Total:
                <Box flexGrow="1"></Box>
                <Typography
                  variant="inherit"
                  component="p"
                  className="valor-total"
                >
                  $ {precioTotalNeto}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={() => {
          setStateAccesoPayment(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">{"Reserva exitosa"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ya recibimos tu pedido, te notificaremos por mail el estado del
              mismo. Gracias por confiar en HomeKids
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link style={{ textDecoration: "none" }} to="/">
              <Button
                onClick={() => {
                  handleClose();
                  setStateAccesoPayment(false);
                }}
                className="button-dialog"
                color="success"
              >
                Aceptar
              </Button>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* DIALOG LO LAMENTO */}
      <Dialog
        disableScrollLock={true}
        open={open2}
        onClose={() => {
          productosCoincidentes?.[0]?.precio_total &&
          precio >= statePrecioMinimo
            ? handleClose2()
            : setStateAccesoPayment(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {"Reserva interrumpida"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Lo sentimos, al parecer alguien ha reservado{" "}
              {productosCoincidentes?.[0]?.coincidentes && (
                <span className="texto-resaltado-dialog">
                  {productosCoincidentes[0].coincidentes}
                </span>
              )}{" "}
              mientras usted estaba en proceso de reserva. Le hemos retirado{" "}
              {productosCoincidentes?.[0]?.cant_coincidentes &&
              productosCoincidentes[0].cant_coincidentes > 1
                ? "esos productos"
                : "ese producto"}{" "}
              del carrito, que ya no se encuentra disponible para la fecha que
              has seleccionado.{" "}
              {productosCoincidentes?.[0]?.coincidentes &&
              precio >= statePrecioMinimo ? (
                <span>
                  Tiene la posibilidad de reservar{" "}
                  {productosCoincidentes?.[0]?.resto && (
                    <span className="texto-resaltado-dialog">
                      {productosCoincidentes[0].resto}
                    </span>
                  )}{" "}
                  que{" "}
                  {productosCoincidentes?.[0]?.cant_resto &&
                  productosCoincidentes[0].cant_resto > 1
                    ? "siguen vigentes"
                    : "sigue vigente"}{" "}
                  en el carrito, o continuar reservando y escoger otros
                  productos si lo desea.
                </span>
              ) : precio <= statePrecioMinimo && precio > 0 ? (
                <span>
                  Pero ahora lamentablemente no alcanza el límite de
                  contratación de $
                  {productosCoincidentes?.[0]?.precio_total && (
                    <span className="texto-resaltado-dialog">
                      {statePrecioMinimo}
                    </span>
                  )}{" "}
                  puesto que el precio a abonar ahora serían $
                  {productosCoincidentes[0] && precio} por lo que será
                  redirigido a la tienda y si usted desea puede seleccionar
                  otro/s producto/s para reservar para la fecha seleccionada y
                  así alcanzar dicho mínimo.
                </span>
              ) : (
                <span>Serás redirigido a la tienda</span>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {productosCoincidentes?.[0]?.precio_total &&
            precio >= statePrecioMinimo ? (
              <Button
                onClick={handleClose2}
                className="button-dialog"
                color="success"
              >
                Aceptar
              </Button>
            ) : (
              <Link style={{ textDecoration: "none" }} to="/">
                <Button
                  onClick={() => {
                    handleClose2();
                    setStateAccesoPayment(false);
                  }}
                  className="button-dialog"
                  color="success"
                >
                  Aceptar
                </Button>
              </Link>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FormPayment;
