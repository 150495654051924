import React from "react";
// Material UI
import { Typography } from "@material-ui/core";
import { Box, Button } from "@mui/material";
// Modules
import { Link } from "react-router-dom";
// Hooks
import { useStateValue } from "../../../../../../../hooks/StateProvider";
// Context
import { useContext } from "react";
import { Context2 } from "../../../../../../../context/Context";

const Total = () => {
  const [{ basket }, dispatch] = useStateValue();

  // Obtener datos del context para reservarSectionDisplay
  const { reservarSectionDisplay } = useContext(Context2);
  const [stateReservarSectionDisplay, setStateReservarSectionDisplay] =
    reservarSectionDisplay;

  // Funcion para cerrar ReservarSection
  const cerrarReservarSection = () => {
    setStateReservarSectionDisplay(!stateReservarSectionDisplay);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        fontFamily={"Montserrat"}
        fontSize={"26px"}
        style={{ marginTop: "10px" }}
      >
        <p>Total:</p>
        <Box flexGrow="1"></Box>
        <Typography variant="inherit" component="p" className="valor-total">
          ${" "}
          {basket
            .reduce((amount, item) => amount + item.price, 0)
            .toLocaleString()
            .replace(",", ".")}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ marginTop: "2rem" }}
      >
        <Typography
          variant="inherit"
          align="center"
          style={{ color: "#D9AE48" }}
          className="sin-productos-txt"
        >
          Costo de envío bonificado
        </Typography>
        <Link style={{ textDecoration: "none" }} to="payment">
          <Button
            className="finalizar-reserva-button"
            variant="contained"
            onClick={cerrarReservarSection}
          >
            Finalizar Reserva
          </Button>
        </Link>
        <br />
        <Button
          className="seguir-reservando-button"
          color="secondary"
          margintop="2rem"
          onClick={cerrarReservarSection}
        >
          Seguir Reservando
        </Button>
      </Box>
    </>
  );
};

export default Total;
