import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import "./Header.css";
import { Button } from "@mui/material";
import logoMinimalista from "../../../assets/images/logos/logo-minimalista.png";

function Header() {
  return (
    <Grid
      container
      className="navbar-payment"
      justifyContent="flex-end"
      alignItems="center"
      position="absolute"
    >
      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
        <Grid container alignItems="center">
          <img className="logo-minimalista" src={logoMinimalista} alt="" />
        </Grid>
      </Grid>

      <Grid item xs={10} sm={10} md={10} lg={10}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          paddingRight={"20px"}
        >
          <Link style={{ textDecoration: "none" }} to="/">
            <Button className="seguir-reservando-btn">Seguir Reservando</Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;
