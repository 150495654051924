import React from "react";
// Material UI
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Typography from "@mui/material/Typography";

function ProductConsultarBtn(props) {
  return (
    <>
      <IconButton
        className="reservar-btn"
        aria-label="Consultar"
        href="https://wa.me/5491163697737"
        target="_blank"
      >
        <WhatsAppIcon fontSize="20px" />
        <Typography
          className="carrito-ver_txt"
          variant="body2"
          color="#730075"
          component="p"
          fontSize="20px"
        >
          Consultar
        </Typography>
      </IconButton>
    </>
  );
}

export default ProductConsultarBtn;
