import React, { useEffect } from "react";
import { Element } from "react-scroll";
// Components
import Header from "./Header";
import Tienda from "./Tienda";
import Contacto from "./Contacto";
import Condiciones from "./Condiciones";
import ReservarSection from "./Tienda/components/ReservarSection";
import Footer from "./Footer";
import Whatsapp from "./Whatsapp";

function Inicio() {
  // Poner fondo del body de color verde
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
  }, []);

  return (
    <>
      <Header />
      <Element name="tienda" id="tienda">
        <Tienda />
      </Element>
      <Element name="condiciones" id="condiciones">
        <Condiciones />
      </Element>
      <Element name="contacto" id="contacto">
        <Contacto />
      </Element>
      {/* Sección de reserva (carrito de compras) */}
      <ReservarSection />
      <Footer />
      <Whatsapp />
    </>
  );
}

export default Inicio;
