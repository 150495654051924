import React from "react";
// Material UI
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Context
import { useContext, useEffect } from "react";
import { Context8 } from "../../context/Context";

import { Link } from "react-router-dom";

function PaymentFinalizado() {
  // Obtener si el acceso a Payment
  const { accesoPayment } = useContext(Context8);
  const [stateAccesoPayment, setStateAccesoPayment] = accesoPayment;

  useEffect(() => {
    document.body.style.backgroundColor = "#3be200";
  }, []);
  return (
    <>
      <div
        container
        justifyContent="center"
        alignItems="center"
        className="container-form"
      ></div>
      <Dialog
        disableScrollLock={true}
        open={true}
        onClose={() => {
          setStateAccesoPayment(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">{"Reserva exitosa"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ya recibimos tu pedido, te notificaremos por mail el estado del
              mismo. Gracias por confiar en HomeKids
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link style={{ textDecoration: "none" }} to="/">
              <Button
                onClick={() => {
                  setStateAccesoPayment(false);
                }}
                className="button-dialog"
                color="success"
              >
                Aceptar
              </Button>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PaymentFinalizado;
