import Pagination from "@mui/material/Pagination";
// Material UI
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Moduls
import { Link } from "react-scroll";
// Hooks
import { useState } from "react";

export default function Paginationn(props) {
  const [activeSection, setActiveSection] = useState("");

  const handleChange = (event, value) => {
    props.setCurrentPage(value);
  };

  // Color
  const theme = createTheme({
    palette: {
      violet: {
        main: "#730075",
        contrastText: "#fff",
        dark: "#730075",
      },
    },
  });

  const handleSetActiveSection = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#DDBC4C",
          borderRadius: "5px",
          paddingBottom: "2px",
        }}
      >
        <p
          style={{
            fontFamily: "Roboto",
            color: "",
            marginBottom: "2px",
          }}
        >
          Páginas
        </p>
        <Link
          style={{ textDecoration: "none" }}
          to="tienda"
          smooth={true}
          offset={-80}
          activeClass="active-scroll-spy"
          onSetActive={handleSetActiveSection}
        >
          <Pagination
            count={props.totalPages}
            page={props.currentPage}
            onChange={handleChange}
            color="violet"
            hidePrevButton={window.innerWidth < 600}
            hideNextButton={window.innerWidth < 600}
          />
        </Link>
      </div>
    </ThemeProvider>
  );
}
