import React from "react";
// Material UI
import { CardContent, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function ProductContent(props) {
  // Función para capitalizar la primera letra de una cadena
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Función para eliminar la última letra "s" de una cadena
  const removeLastS = (str) => {
    if (str.endsWith("s")) {
      return str.slice(0, -1);
    }
    return str;
  };
  return (
    <CardContent>
      <Grid container style={{ alignItems: "flex-end" }}>
        <Grid item xs={7} sm={7} md={7} lg={7}>
          <Typography variant="body2" color="white" component="p">
            Tamaño: {props.size}
            <br />
            Edad: {props.age}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} md={5} lg={5} item>
          {props.plotter === true ? (
            <Typography variant="body2" color="white" component="p">
              Incluye plotter
            </Typography>
          ) : null}
          <Box
            component="div"
            bgcolor={
              props.filtro[0] === "inflables"
                ? "#f46d1f"
                : props.filtro[0] === "acuaticos"
                ? "#51d1f6"
                : props.filtro[0] === "carreras"
                ? "#ea0d2e"
                : props.filtro[0] === "kermesse"
                ? "#fdf100"
                : props.filtro[0] === "juegos"
                ? "#18c2a6"
                : props.filtro[0] === "livings" && props.filtro[1] === "living"
                ? "#ffffff"
                : props.filtro[0] === "livings" &&
                  props.filtro[1] === "miniliving"
                ? "#e114e8"
                : props.filtro[0] === "plaza blanda"
                ? "#8b8a8b"
                : props.filtro[0] === "combos"
                ? "#0c21dc"
                : props.filtro[0] === "catering"
                ? "#0e7812"
                : null
            }
            color={
              (props.filtro[0] === "livings" && props.filtro[1] === "living") ||
              props.filtro[0] === "kermesse"
                ? "#000"
                : "#fff"
            }
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderRadius={1}
          >
            <Typography variant="body2" component="p">
              {props.filtro[0] !== "livings"
                ? removeLastS(props.filtro[0]).toUpperCase()
                : props.filtro[1].toUpperCase()}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default ProductContent;
