import React from "react";
// Material UI
import IconButton from "@mui/material/IconButton";
import { AddShoppingCart } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Hooks
import { actionTypes } from "../../../../../../reducer";
import { useStateValue } from "../../../../../../hooks/StateProvider";
import { useHistoryEffects } from "../../../../../../hooks/useHistoryEffects.js";
// Context
import { useContext, useState } from "react";
import { Context2, Context8 } from "../../../../../../context/Context";
// Moduls
import { Link } from "react-scroll";
// Hooks
import { IsDateWithinRange } from "../../../../../../hooks/dateUtils";

function ProductReservarBtn(props) {
  // Valor booleano si es temporada de acuaticos
  const isWithinRange = IsDateWithinRange();
  const [{ basket }, dispatch] = useStateValue();
  // Obtener datos del context
  const { reservarSectionDisplay } = useContext(Context2);
  const [stateReservarSectionDisplay, setStateReservarSectionDisplay] =
    reservarSectionDisplay;
  const { accesoPayment } = useContext(Context8);
  const [stateAccesoPayment, setStateAccesoPayment] = accesoPayment;
  // Dialog
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };
  const [activeSection, setActiveSection] = useState("");

  const handleSetActiveSection = (sectionId) => {
    setActiveSection(sectionId);
  };
  const addToBasket = () => {
    const id = props.id;
    const item = props.item;
    const name = props.name;
    const price = props.price;
    const image = props.image;
    const size = props.size;
    const age = props.age;
    const city = props.city;
    const date = props.date;
    const filtro = props.filtro;

    setStateAccesoPayment(true);

    if (city !== 0 && city !== null && date !== undefined && date !== null) {
      let productExist = false;
      if (basket.length > 0) {
        for (let i = 0; i < basket.length; i++) {
          if (basket[i].id === props.id) {
            productExist = true;
            break;
          }
        }
        if (productExist === false) {
          dispatch({
            type: actionTypes.ADD_TO_BASKET,
            item: {
              id,
              item,
              name,
              price,
              image,
              size,
              age,
              filtro,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.ADD_TO_BASKET,
          item: {
            id,
            item,
            name,
            price,
            image,
            size,
            age,
            filtro,
          },
        });
      }

      // Abrir ReservarSection
      setStateReservarSectionDisplay("block");
      document.body.style.overflow = "hidden";
    } else {
      setOpen(true);
      document.body.style.overflow = "hidden";
    }
  };

  // Cerrar dialog con flecha atras
  useHistoryEffects(open, setOpen);

  return (
    <>
      <IconButton
        className={
          props.filtro.includes("acuaticos") && !isWithinRange
            ? "deshabilitado-reservar-btn"
            : "reservar-btn"
        }
        aria-label="Reservar"
        onClick={addToBasket}
      >
        <AddShoppingCart fontSize="20px" />
        <Typography
          className="carrito-ver_txt"
          variant="body2"
          color="#730075"
          component="p"
          fontSize="20px"
        >
          Reservar
        </Typography>
      </IconButton>
      {/* Dialog alert */}
      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          ClicksDisabled: true,
        }}
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {"Campos incompletos"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Por favor, selecciona una{" "}
              <span className="texto-resaltado-dialog">FECHA</span> y una{" "}
              <span className="texto-resaltado-dialog">CIUDAD</span> para poder
              reservar, ver disponibilidad y precios.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link
              style={{ textDecoration: "none" }}
              to="tienda"
              smooth={true}
              offset={-80}
              activeClass="active-scroll-spy"
              onSetActive={handleSetActiveSection}
            >
              <Button
                onClick={handleClose}
                className="button-dialog"
                color="success"
              >
                Aceptar
              </Button>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProductReservarBtn;
