import * as React from "react";
// Material UI
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CloseIcon from "@mui/icons-material/Close";
// Components
import PedidosPendientes from "./components/pedidosPendientes";
import DiasDeshabilitados from "./components/diasDeshabilitados";
import ConfiguracionReservas from "./components/configuracionReservas";
import InflablesDeshabilitados from "./components/inflablesDeshabilitados";
// Styles
import "./Admin.css";
//Auth
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Admin() {
  const [value, setValue] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setIsAuthenticated(true);
      })
      .catch((error) => {
        setIsAuthenticated(false);
        setLoginError(true);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!isAuthenticated && (
        <form onSubmit={handleFormSubmit} className="login-form">
          <input
            type="text"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
          />
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              sx={{ mt: 2 }}
            />
            <IconButton
              onClick={toggleShowPassword}
              className="password-toggle"
              size="small"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </div>
          <button
            type="submit"
            className="login_btn"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Login
          </button>
          {loginError && (
            <Alert severity="error" className="error-message">
              <AlertTitle>Error</AlertTitle>
              Usuario o contraseña incorrecta
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setLoginError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Alert>
          )}
        </form>
      )}

      {isAuthenticated && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              display={{ lg: "block", md: "block", sm: "block", xs: "none" }}
            >
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ flexDirection: { xs: "column", sm: "row" } }}
              >
                <Tab label="Pedidos pendientes" {...a11yProps(0)} />
                <Tab label="Días deshabilitados" {...a11yProps(1)} />
                <Tab label="Reservas Automáticas/Manuales" {...a11yProps(2)} />
                <Tab label="Inflables deshabilitados" {...a11yProps(3)} />
              </Tabs>
            </Grid>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Select
                value={value}
                onChange={handleSelectChange}
                variant="standard"
                fullWidth
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "8px",
                  "&:focus": {
                    borderColor: "#1976d2",
                  },
                  "& svg": {
                    color: "#666",
                  },
                }}
                IconComponent={({ ...props }) => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    {...props}
                  >
                    <path d="M7 10l5 5 5-5z" />
                  </svg>
                )}
              >
                <MenuItem value={0}>Pedidos pendientes</MenuItem>
                <MenuItem value={1}>Días deshabilitados</MenuItem>
                <MenuItem value={2}>
                  Configuración de Reservas Automáticas/Manuales
                </MenuItem>
                <MenuItem value={3}>Inflables deshabilitados</MenuItem>
              </Select>
            </Box>
          </Box>
          <TabPanel value={value} index={0}>
            <PedidosPendientes />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DiasDeshabilitados />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ConfiguracionReservas />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <InflablesDeshabilitados />
          </TabPanel>
        </>
      )}
    </Box>
  );
}
