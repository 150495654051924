import React from "react";
import { useState, useEffect } from "react";
// Material UI
import esLocale from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
// Firestore
import { doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../../config";
// Data
import products from "../../../product-data";
// Styles
import "../Admin.css";

const createData = (name, deshabilitados, tipo) => {
  return {
    name,
    deshabilitados,
    tipo,
  };
};

export default function InflablesDeshabilitados() {
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");
  const [dateFormat, setDateFormat] = useState("");
  // Se declara estado para guardar valor booleano segun si existe o no fecha seleccionada en firestore
  const [idsDeshabilitados, setIdsDeshabilitados] = useState();
  // Variables de loading
  const [isLoading, setIsLoading] = useState(false);
  const [habilitar, setHabilitar] = useState();
  const [productId, setProductId] = useState();
  const [productItems, setProductItems] = useState();
  const [productName, setProductName] = useState();
  // Estado que cambia a true cuando se selecciona un fecha dandole al boton "Ok"
  const [mostrarTabla, setMostrarTabla] = useState(false);

  // Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (fechaSeleccionada !== "") {
      const day = fechaSeleccionada.getDate().toString().padStart(2, "0");
      const month = (fechaSeleccionada.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const year = fechaSeleccionada.getFullYear().toString();

      setDateFormat(`${day}-${month}-${year}`);
    }
  }, [fechaSeleccionada]);

  // Obtener los inflables reservados o deshabilitados manualmente para la fecha seleccionada
  const verificarExistenciaDocumento = async () => {
    setMostrarTabla(true);
    setIsLoading(true);
    if (fechaSeleccionada !== "") {
      const docRef = doc(db, "RESERVAS", dateFormat);
      try {
        const docSnapshot = await getDoc(docRef);
        const existe = docSnapshot.exists();
        const cantIdsDeshabilitados =
          existe && docSnapshot.data() ? docSnapshot.data().ids : null;

        setIdsDeshabilitados(cantIdsDeshabilitados);
      } catch (error) {
        console.log("Error al obtener el documento:", error);
      }
    }
    setIsLoading(false);
  };

  // Agregar datos Firestore
  const Deshabilitar = async () => {
    setIsLoading(true);

    const docRef = doc(db, "RESERVAS", dateFormat);
    const docSnap = await getDoc(docRef);

    let currentIds = [];
    if (docSnap.exists() && docSnap.data().hasOwnProperty("ids")) {
      currentIds = Array.isArray(docSnap.data().ids)
        ? docSnap.data().ids
        : [docSnap.data().ids];
    }
    let currentItems = [];
    if (docSnap.exists() && docSnap.data().hasOwnProperty("items")) {
      currentItems = Array.isArray(docSnap.data().items)
        ? docSnap.data().items
        : [docSnap.data().items];
    }

    const newIds = [...currentIds, productId]; // Concatenar el nuevo id
    const newItems = [...currentItems, ...productItems]; // Concatenar el nuevo item

    if (docSnap.exists()) {
      // El documento existe
      await updateDoc(docRef, {
        ids: newIds,
        items: newItems,
      });
    } else {
      // El documento no existe
      await setDoc(docRef, {
        habilitado: true,
        limite: parseInt(8),
        automatico: true,
        ids: newIds,
        items: newItems,
        nroPedidos: [],
      });
    }
    await verificarExistenciaDocumento();
  };

  // Habilitar: eliminar inflable de base de datos
  const Habilitar = async () => {
    setIsLoading(true);

    const docRef = doc(db, "RESERVAS", dateFormat);
    const docSnap = await getDoc(docRef);

    let currentIds = [];
    if (docSnap.exists() && docSnap.data().hasOwnProperty("ids")) {
      currentIds = Array.isArray(docSnap.data().ids)
        ? docSnap.data().ids
        : [docSnap.data().ids];
    }
    let currentItems = [];
    if (docSnap.exists() && docSnap.data().hasOwnProperty("items")) {
      currentItems = Array.isArray(docSnap.data().items)
        ? docSnap.data().items
        : [docSnap.data().items];
    }
    const newIds = currentIds.filter((id) => id !== productId); // Eliminar el id del inflable indicado
    const newItems = currentItems.filter(
      (item) => !productItems.includes(item)
    );
    // Eliminar el items del inflable indicado

    await updateDoc(docRef, {
      ids: newIds,
      items: newItems,
    });
    await verificarExistenciaDocumento();
  };

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.deshabilitados}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products
                      .filter((product) => product.filtro.includes(row.tipo))
                      .map((product) => (
                        <TableRow
                          className={
                            idsDeshabilitados !== undefined &&
                            idsDeshabilitados !== null &&
                            idsDeshabilitados.includes(product.id)
                              ? "celdaDeshabilitada"
                              : null
                          }
                          key={product.name}
                        >
                          <TableCell component="th" scope="row">
                            {product.name}
                          </TableCell>
                          <TableCell>
                            {idsDeshabilitados !== undefined &&
                            idsDeshabilitados !== null &&
                            idsDeshabilitados.includes(product.id) ? (
                              <Button
                                onClick={() => {
                                  setOpenDialog(true);
                                  setHabilitar(true);
                                  setProductId(product.id);
                                  if (typeof product.item === "number") {
                                    setProductItems([product.item]);
                                  } else if (Array.isArray(product.item)) {
                                    setProductItems(product.item);
                                  }
                                  setProductName(product.name);
                                }}
                              >
                                Habilitar
                              </Button>
                            ) : (
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setOpenDialog(true);
                                  setHabilitar(false);
                                  setProductId(product.id);
                                  if (typeof product.item === "number") {
                                    setProductItems([product.item]);
                                  } else if (Array.isArray(product.item)) {
                                    setProductItems(product.item);
                                  }
                                  setProductName(product.name);
                                }}
                              >
                                Deshabilitar
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  const rows = [
    createData("Inflables", 0, "inflables"),
    createData("Acuáticos", 0, "acuaticos"),
    createData("Carreras", 0, "carreras"),
    createData("Kermesse", 0, "kermesse"),
    createData("Juegos", 0, "juegos"),
    createData("Livings", 0, "livings"),
    createData("Plaza Blanda", 0, "plaza blanda"),
  ];

  rows.forEach((row) => {
    if (idsDeshabilitados !== null && idsDeshabilitados !== undefined) {
      const filteredIdsDeshabilitados = products
        .filter(
          (product) =>
            product.filtro.includes(row.tipo) &&
            idsDeshabilitados.includes(product.id)
        )
        .map((product) => product.id);

      const totalSizes = filteredIdsDeshabilitados.length;

      row.deshabilitados = totalSizes;
    }
  });

  return (
    <>
      <Grid
        container
        className="container-diasDeshabilitados"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={2}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <MobileDatePicker
              label="Seleccione una fecha"
              value={fechaSeleccionada}
              disablePast
              textField={(params) => <TextField {...params} />}
              onChange={(nuevaFechaSeleccionada) => {
                setFechaSeleccionada(nuevaFechaSeleccionada);
              }}
              onAccept={(nuevaFechaSeleccionada) => {
                verificarExistenciaDocumento(nuevaFechaSeleccionada);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={3}>
          <p className="aclaracion-txt">
            {idsDeshabilitados !== null && idsDeshabilitados !== undefined
              ? `Total deshabilitados: ${idsDeshabilitados.length}`
              : null}
          </p>
        </Grid>
      </Grid>

      {mostrarTabla ? (
        isLoading ? (
          <div className="loading-container">
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Articulo</TableCell>
                  <TableCell align="right">Deshabilitados/Reservados</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : null}
      <Dialog
        disableScrollLock={true}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {habilitar ? "Habilitar" : "Deshabilitar"} inflable
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Está seguro que quiere{" "}
              <span
                className={
                  habilitar
                    ? "texto-resaltado-dialog-verde"
                    : "texto-resaltado-dialog-rojo"
                }
              >
                {habilitar ? "habilitar" : "deshabilitar"}
              </span>{" "}
              el inflable{" "}
              <span className="texto-resaltado-dialog"> "{productName}"</span>{" "}
              para el día {dateFormat}?<br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="button-dialog"
              color="error"
            >
              Cancelar
            </Button>

            {habilitar ? (
              <Button
                onClick={() => {
                  setOpenDialog(false);
                  Habilitar();
                }}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setOpenDialog(false);
                  Deshabilitar();
                }}
                className="button-dialog"
                color="success"
              >
                Confirmar
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
