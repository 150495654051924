import * as React from "react";
// Estilos
import "./ProductCard.css";
// Importo componentes atomos
import ProductNamePrice from "./components/ProductNamePrice";
import ProductImage from "./components/ProductImage";
import ProductContent from "./components/ProductContent";
import ProductReservarBtn from "./components/ProductReservar_btn";
import ProductConsultarBtn from "./components/ProductConsultar_btn";
import ProductVerBtn from "./components/ProductVer_btn";
// Otras importaciones
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { Box } from "@mui/material";
// Context
import { useContext } from "react";
import { Context, Context3 } from "../../../../../context/Context";

export default function ProductCard({
  product: {
    id,
    item,
    name,
    image,
    size,
    age,
    plotter,
    descripcion,
    price,
    productCardClass,
    disponibleTxt,
    filtro,
    consultar,
  },
}) {
  // Obtener datos del context para base de datos
  const { date } = useContext(Context);
  const [stateDate, setStateDate] = date;

  // Declaro variables del context
  const { cityZona } = useContext(Context3);
  const [stateCityZona, setStateCityZona] = cityZona;
  return (
    <Card
      className={
        productCardClass !== undefined ? productCardClass : "habilitado"
      }
    >
      {/* Nombre y precio */}

      <ProductNamePrice
        filtro={filtro}
        price={!consultar ? price : null}
        name={name}
        disponibletxt={disponibleTxt}
      />

      {/* Imagen */}
      <ProductImage image={image} name={name} />
      {/* Contenido */}
      <ProductContent size={size} age={age} plotter={plotter} filtro={filtro} />
      {/* Botones */}
      <CardActions disableSpacing>
        {/* Boton de reserva (agregar al carrito) */}
        {}
        {consultar === undefined ? (
          <div className="deshabilitado-reservar-btn-div">
            <ProductReservarBtn
              id={id}
              item={item}
              name={name}
              price={price}
              image={image}
              size={size}
              age={age}
              city={stateCityZona}
              date={stateDate}
              filtro={filtro}
            />
          </div>
        ) : (
          <ProductConsultarBtn />
        )}
        {/* Caja vacía para separar botones */}
        <Box flexGrow="1"></Box>
        {/* Boton de ver (más info del producto) */}
        <ProductVerBtn
          id={id}
          item={item}
          name={name}
          price={price}
          image={image}
          size={size}
          age={age}
          plotter={plotter}
          descripcion={descripcion}
          city={stateCityZona}
          date={stateDate}
          productDisponibilidad={productCardClass}
          consultar={consultar}
          filtro={filtro}
        />
      </CardActions>
    </Card>
  );
}
