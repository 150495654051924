import React, { createContext, useState } from "react";

export const Context = createContext();
export const Fecha = ({ children }) => {
  const [formattedDate, setFormattedDate] = useState(null);
  const [rawDate, setRawDate] = useState(null);

  return (
    <Context.Provider
      value={{
        date: [formattedDate, setFormattedDate],
        rawDate: [rawDate, setRawDate],
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const Context2 = createContext();
export const ReservarSectionDisplay = ({ children }) => {
  const [reservarSectionDisplay, setReservarSectionDisplay] = useState(false);
  return (
    <Context2.Provider
      value={{
        reservarSectionDisplay: [
          reservarSectionDisplay,
          setReservarSectionDisplay,
        ],
      }}
    >
      {children}
    </Context2.Provider>
  );
};

export const Context3 = createContext();
export const CityZona = ({ children }) => {
  const [cityZona, setCityZona] = useState(0);
  return (
    <Context3.Provider value={{ cityZona: [cityZona, setCityZona] }}>
      {children}
    </Context3.Provider>
  );
};

export const Context4 = createContext();
export const CitySelected = ({ children }) => {
  const [citySelected, setCitySelected] = useState("");
  return (
    <Context4.Provider
      value={{ citySelected: [citySelected, setCitySelected] }}
    >
      {children}
    </Context4.Provider>
  );
};

export const Context5 = createContext();
export const DateMonth = ({ children }) => {
  const [dateMonth, setDateMonth] = useState(null);
  return (
    <Context5.Provider value={{ dateMonth: [dateMonth, setDateMonth] }}>
      {children}
    </Context5.Provider>
  );
};

export const Context6 = createContext();
export const ResetTienda = ({ children }) => {
  const [resetTienda, setResetTienda] = useState(false);
  return (
    <Context6.Provider value={{ resetTienda: [resetTienda, setResetTienda] }}>
      {children}
    </Context6.Provider>
  );
};

export const Context7 = createContext();
export const PrecioMinimo = ({ children }) => {
  const [precioMinimo, setPrecioMinimo] = useState(false);
  return (
    <Context7.Provider
      value={{ precioMinimo: [precioMinimo, setPrecioMinimo] }}
    >
      {children}
    </Context7.Provider>
  );
};

export const Context8 = createContext();
export const AccesoPayment = ({ children }) => {
  const [accesoPayment, setAccesoPayment] = useState(false);
  return (
    <Context8.Provider
      value={{ accesoPayment: [accesoPayment, setAccesoPayment] }}
    >
      {children}
    </Context8.Provider>
  );
};
export const Context9 = createContext();
export const FiltroSeleccionado = ({ children }) => {
  const [filtroSeleccionado, setFiltroSeleccionado] = useState(["todo"]);
  return (
    <Context9.Provider
      value={{
        filtroSeleccionado: [filtroSeleccionado, setFiltroSeleccionado],
      }}
    >
      {children}
    </Context9.Provider>
  );
};
export const Context10 = createContext();
export const OrdenSeleccionado = ({ children }) => {
  const [ordenSeleccionado, setOrdenSeleccionado] = useState("a-z"); // cambiar a "destacados"
  return (
    <Context10.Provider
      value={{
        ordenSeleccionado: [ordenSeleccionado, setOrdenSeleccionado],
      }}
    >
      {children}
    </Context10.Provider>
  );
};
