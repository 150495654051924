import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import CarritoDeCompras from "./components/CarritoDeCompras";
import CloseIcon from "@mui/icons-material/Close";
import logoMinimalista from "../../../../../assets/images/logos/logo-minimalista.png";
import debounce from "lodash/debounce";
// Styles
import "./Navbar.css";
// Components
import Filter from "../../../Tienda/components/Filter";
// Hooks
import { useHistoryEffects } from "../../../../../hooks/useHistoryEffects.js";

function MyNavbar({ threshold }) {
  const [isSticky, setIsSticky] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollOffset = window.pageYOffset;
      setIsSticky(scrollOffset > threshold);
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold]);

  // Para modificar offset de contacto en tamaños sm y xs de pantalla
  const getOffset = () => {
    const screenSize = window.innerWidth;

    if (screenSize < 600) {
      // Tamaño "xs"
      return -79;
    } else if (screenSize < 960) {
      // Tamaño "sm"
      return -80;
    } else {
      return -80;
    }
  };

  // Cerrar menu responsive con flecha atras
  useHistoryEffects(showNavbar, setShowNavbar);

  useEffect(() => {
    if (showNavbar) {
      document.body.classList.add("body-scroll-disabled");
    } else {
      document.body.classList.remove("body-scroll-disabled");
    }
  }, [showNavbar]);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav className={`${isSticky ? "sticky" : ""} navbar`}>
      {/* MENU RESPONSIVE */}
      <div
        className={`fondo-oscuro ${showNavbar && "active"}`}
        onClick={handleShowNavbar}
      ></div>
      <Grid
        container
        display={{ lg: "none", md: "none", sm: "block", xs: "block" }}
        className={`nav-responsive  ${showNavbar && "active"}`}
      >
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ paddingLeft: "15px", paddingTop: "15px" }}
        >
          <Grid item xs={10} sm={10}>
            <img className="logo-minimalista" src={logoMinimalista} alt="" />
          </Grid>
          <Grid
            item
            xs={1.5}
            sm={1.5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon className="icons" onClick={handleShowNavbar} />
          </Grid>
          <Grid item xs={0.5} sm={0.5}></Grid>
        </Grid>
        <hr className="orange-bar" />
        <Grid
          container
          direction="column"
          spacing={4}
          sx={{ marginTop: "20px" }}
        >
          <Grid style={{ cursor: "pointer" }} item>
            <Link
              style={{ textDecoration: "none" }}
              data-to-scrollspy-id="tienda"
              to="tienda"
              smooth={true}
              offset={-80}
              spy={true}
              activeClass="active-scroll-spy"
              onClick={handleShowNavbar}
            >
              <span className="enlace-menu-responsive">TIENDA</span>
            </Link>
          </Grid>
          <Grid item marginLeft="10px">
            <Filter />
          </Grid>
          <Grid
            style={{ cursor: "pointer" }}
            item
            sx={{ marginBottom: "10px" }}
          >
            <Link
              style={{ textDecoration: "none" }}
              data-to-scrollspy-id="condiciones"
              to="condiciones"
              smooth={true}
              offset={-80}
              spy={true}
              activeClass="active-scroll-spy"
              onClick={handleShowNavbar}
            >
              <span className="enlace-menu-responsive">
                TERMINOS Y CONDICIONES
              </span>
            </Link>
          </Grid>
          <Grid style={{ cursor: "pointer" }} item>
            <Link
              style={{ textDecoration: "none" }}
              data-to-scrollspy-id="contacto"
              to="contacto"
              smooth={true}
              offset={getOffset()}
              spy={true}
              activeClass="active-scroll-spy"
              onClick={handleShowNavbar}
            >
              <span className="enlace-menu-responsive">CONTACTO</span>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      {/* MENU NORMAL */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        height={"100%"}
        fontSize={{ md: "18px", lg: "22px" }}
      >
        <Grid
          item
          xs={1.5}
          sm={1}
          md={0}
          lg={0}
          display={{ lg: "none", md: "none", sm: "block", xs: "block" }}
          className="menu-icon"
          onClick={handleShowNavbar}
        >
          <Grid container justifyContent="flex-end" alignItems="center">
            <MenuIcon className="icons" />
          </Grid>
        </Grid>
        <Grid item md={2.2} lg={1.6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Link
              style={{ textDecoration: "none" }}
              to="inicio"
              smooth={true}
              offset={-80}
              spy={true}
              onClick={handleLogoClick}
            >
              <img className="logo-minimalista" src={logoMinimalista} alt="" />
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          md={8.5}
          lg={7.1}
          display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
          className={"nav-elements"}
        >
          <ul>
            <li>
              <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                data-to-scrollspy-id="tienda"
                to="tienda"
                smooth={true}
                offset={-80}
                spy={true}
                activeClass="active-scroll-spy"
              >
                <span>TIENDA</span>
              </Link>
            </li>
            <li>
              <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                data-to-scrollspy-id="condiciones"
                to="condiciones"
                smooth={true}
                offset={-80}
                spy={true}
                activeClass="active-scroll-spy"
              >
                <span>TERMINOS Y CONDICIONES</span>
              </Link>
            </li>
            <li>
              <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                data-to-scrollspy-id="contacto"
                to="contacto"
                smooth={true}
                offset={-80}
                spy={true}
                activeClass="active-scroll-spy"
              >
                <span>CONTACTO</span>
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item md={0.5} lg={1}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            paddingRight={"20px"}
          >
            <CarritoDeCompras />
          </Grid>
        </Grid>
      </Grid>
    </nav>
  );
}

export default MyNavbar;
